import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, concat } from '@apollo/client';
import { apiConfigUrl, domainOwnerUrl } from './config';
import { Provider } from 'react-redux';
import { store } from './store/store';

const httpLink = new HttpLink({
  uri: apiConfigUrl
});

const authLink = new ApolloLink((operation, forward) => {
  // Add the custom header to the request
  operation.setContext({
    headers: {
      'domainOwner': domainOwnerUrl
    }
  });

  // Call the next link in the middleware chain
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false
  }),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();