import React from 'react'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import './pageHeading.scss'
import { useNavigate } from 'react-router-dom';

function PageHeading({pageTitle}) {
  const navigate = useNavigate()
  return (
    <>
    <div className='pageHeading'>
        <h2>{pageTitle}</h2>
        <div className='pageHeadingBox'>
          <p onClick={() => {navigate("/")}}>Home</p> <MdOutlineKeyboardArrowRight />  <p>{pageTitle}</p>
        </div>
    </div>
    </>
  )
}

export default PageHeading
