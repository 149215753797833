// toastSlice.js
import { createSlice } from '@reduxjs/toolkit';

const toastSlice = createSlice({
    name: 'toast',
    initialState: {
        message: '',
        type: '', // e.g., 'success', 'error'
        isVisible: false,
    },
    reducers: {
        showToast(state, action) {
            const { message, type } = action.payload;
            state.message = message;
            state.type = type;
            state.isVisible = true;
        },
        hideToast(state) {
            state.isVisible = false;
        },
    },
});

export const { showToast, hideToast } = toastSlice.actions;
export default toastSlice.reducer;
