import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sessionId: sessionStorage.getItem('sessionString') || null,
    checkedItems: [],
    selectedDate: null,
    selectedTime: '',
    selectedMember: null,
    selectedPayment: null,
    selectedPaymentOption: null,
    activeKey: '',
    step: 1,
    loading: true,
    error: null,
    searchQuery: '',
    navItems: [],
    treatmentsData: [],
    clinicData: {},
    nextAvailableDate: '',
    endDate: '',
    dates: [],
    times: [],
    members: [],
    depositAmount: 0,
    paymentAmount: 0,
    treatmentAmount : 0,
    checkboxLoaderId: 0,
    timeLoader: false,
    isNextStep: false,
    amountToPayNow: 0,
    remainingAmountToPay: 0,
    duration: 0,
    paymentOptions: {},
    companyData: {},
    bookingId: 0,
    paymentData: {},
    formData: {
        name: '',
        lastName: '',
        email: '',
    },
    errors: {},
    payLaterData: [],
    payLaterMonthlyData: {},
    couponCodeName:'',
    couponDiscount: 0,
    couponDiscountType : null,
    couponDiscountUnit:0
    
};

const treatmentSlice = createSlice({
    name: 'treatment',
    initialState,
    reducers: {
        setSessionId(state, action) {
            state.sessionId = action.payload;
        },
        setCheckedItems(state, action) {
            state.checkedItems = action.payload;
        },
        setSelectedDate(state, action) {
            state.selectedDate = action.payload;
        },
        setSelectedTime(state, action) {
            state.selectedTime = action.payload;
        },
        setSelectedMember(state, action) {
            state.selectedMember = action.payload;
        },
        setSelectedPayment(state, action) {
            state.selectedPayment = action.payload;
        },
        setSelectedPaymentOption(state, action) {
            state.selectedPaymentOption = action.payload;
        },
        setActiveKey(state, action) {
            state.activeKey = action.payload;
        },
        setStep(state, action) {
            state.step = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setSearchQuery(state, action) {
            state.searchQuery = action.payload;
        },
        setNavItems(state, action) {
            state.navItems = action.payload;
        },
        setTreatmentsData(state, action) {
            state.treatmentsData = action.payload;
        },
        setClinicData(state, action) {
            state.clinicData = action.payload;
        },
        setNextAvailableDate(state, action) {
            state.nextAvailableDate = action.payload;
        },
        setEndDate(state, action) {
            state.endDate = action.payload;
        },
        setDates(state, action) {
            state.dates = action.payload;
        },
        setTimes(state, action) {
            state.times = action.payload;
        },
        setMembers(state, action) {
            state.members = action.payload;
        },
        setDepositAmount(state, action) {
            state.depositAmount = action.payload;
        },
        setPaymentAmount(state, action) {
            state.paymentAmount = action.payload;
        },
        setTreatmentAmount(state, action) {
            state.treatmentAmount = action.payload;
        },
        setCouponDiscount(state, action) {
            state.couponDiscount = action.payload
        },
        setCouponDiscountType(state, action) {
            state.couponDiscountType = action.payload
        },
        setCouponDiscountUnit(state, action) {
            state.couponDiscountUnit = action.payload
        },
        setCouponCodeName(state, action) {
            state.couponCodeName = action.payload
        },
        setCheckboxLoaderId(state, action) {
            state.checkboxLoaderId = action.payload;
        },
        setTimeLoader(state, action) {
            state.timeLoader = action.payload;
        },
        setIsNextStep(state, action) {
            state.isNextStep = action.payload;
        },
        setAmountToPayNow(state, action) {
            state.amountToPayNow = action.payload;
        },
        setRemainingAmountToPay(state, action) {
            state.remainingAmountToPay = action.payload;
        },
        setDuration(state, action) {
            state.duration = action.payload;
        },
        setPaymentOptions(state, action) {
            state.paymentOptions = action.payload;
        },
        setCompanyData(state, action) {
            state.companyData = action.payload;
        },
        setBookingId(state, action) {
            state.bookingId = action.payload;
        },
        setPaymentData(state, action) {
            state.paymentData = action.payload;
        },
        setFormData(state, action) {
            state.formData = { ...state.formData, ...action.payload };
        },
        setErrors(state, action) {
            state.errors = { ...state.errors, ...action.payload };
        },
        clearErrors(state) {
            state.errors = {};
        },
        setPayLa8rData(state, action){
            state.payLaterData = action.payload;
        },
        setPayLaterMonthlyData(state, action) {
            state.payLaterMonthlyData = action.payload;
        },
        resetState(state) {
            return initialState;
        }
    },
});

export const {
    setSessionId,
    setCheckedItems,
    setSelectedDate,
    setSelectedTime,
    setSelectedMember,
    setSelectedPayment,
    setSelectedPaymentOption,
    setActiveKey,
    setStep,
    setLoading,
    setError,
    setSearchQuery,
    setNavItems,
    setTreatmentsData,
    setClinicData,
    setNextAvailableDate,
    setEndDate,
    setDates,
    setTimes,
    setMembers,
    setDepositAmount,
    setPaymentAmount,
    setCheckboxLoaderId,
    setTimeLoader,
    setIsNextStep,
    setAmountToPayNow,
    setRemainingAmountToPay,
    setDuration,
    setPaymentOptions,
    setCompanyData,
    setBookingId,
    setPaymentData,
    setFormData,
    setErrors,
    clearErrors,
    setPayLa8rData,
    resetState,
    setPayLaterMonthlyData,
    setTreatmentAmount,
    setCouponDiscount,
    setCouponDiscountType,
    setCouponDiscountUnit,
    setCouponCodeName
} = treatmentSlice.actions;

export default treatmentSlice.reducer;
