export const apiConfigUrl = process.env.REACT_APP_GRAPHQL_URL;
export const domainOwnerUrl = process.env.REACT_APP_DOMAIN_OWNER_URL ? process.env.REACT_APP_DOMAIN_OWNER_URL : "";
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const  PAYL8R_USERNAME = process.env.REACT_APP_PAYL8R_USERNAME;
export const frontendURL =  process.env.REACT_APP_FRONTEND_URL

export const config = {
    API_URL: apiConfigUrl,
    DOMAIN_OWNER_URL : domainOwnerUrl,
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    RYFT_PUBLIC_KEY: process.env.REACT_APP_RYFT_PUBLIC_KEY,
    PAYL8R_USERNAME : PAYL8R_USERNAME
}