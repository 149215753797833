import React from 'react'
import { useNavigate } from 'react-router-dom';

function PrimaryButton({btnLink, btnName,onClick}) {
  const navigate = useNavigate()
  return (
    <>
    <div className='primary-btn-box'>
        <button className='primary-btn' onClick={btnLink ? () => {navigate(`/${btnLink}`)} : onClick}>{btnName}</button>
    </div>
    </>
  )
}

export default PrimaryButton
