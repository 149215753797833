import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { IoIosSearch } from "react-icons/io";
import { MdStar } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import './treatment.scss'
import Slider from "react-slick";
import SecHeading from '../../components/secHeading/SecHeading';
import { useNavigate } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import Loader from '../../components/loader/Loader';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { frontendURL, PAYL8R_USERNAME, STRIPE_PUBLIC_KEY } from '../../config';
import RyftComponent from '../../components/ryft/RyftComponent';
import moment from 'moment';
import CheckoutForm from '../../components/stripe/CheckoutForm';
import { useDispatch, useSelector } from 'react-redux';
import {
    GET_BUSINESS_CLINIC_DATA,
    TREATMENT_LIST,
    GET_NEXT_AVAILABLE_DATE,
    GET_CLINIC_AVAILABLE_DATE,
    GET_TIME_SLOTES,
    GET_TEAM_MEMBERS,
    CREATE_SPRINT_TREAT_BOOKING,
    GET_RYFT_PAYMENT_TOKEN_QUERY,
    PAY_LATER_REQUEST_MUTATION,
    GET_DISCOUNT
} from '../../graphql/treatment';
import { setCheckedItems, setSelectedDate, setSelectedTime, setSelectedMember, setSelectedPayment, setSelectedPaymentOption, setActiveKey, setStep, setLoading, setError, setSearchQuery, setNavItems, setTreatmentsData, setClinicData, setNextAvailableDate, setEndDate, setDates, setTimes, setMembers, setDepositAmount, setPaymentAmount, setCheckboxLoaderId, setTimeLoader, setIsNextStep, setAmountToPayNow, setRemainingAmountToPay, setDuration, setPaymentOptions, setCompanyData, setBookingId, setPaymentData, setFormData, setErrors, clearErrors, setPayLa8rData, resetState, setPayLaterMonthlyData, setTreatmentAmount, setCouponDiscount,setCouponDiscountType, setCouponDiscountUnit, setCouponCodeName } from '../../store/slices/treatmentSlice';

import { showToast } from '../../store/slices/toastSlice';
import axios from 'axios';
import CustomRadioButton from '../../components/cusRadiobutton/CustomRadioButton';
import CustomInputbox from '../../components/inputbox/CustomInputbox';
import SecondaryButton from '../../components/commonButton/SecondaryButton';
import { Scrollspy } from "@makotot/ghostui";
import PayLater from '../../components/payL8r/payl8r';
import CustomModal from '../../components/customModal/CustomModal';
import PrimaryButton from '../../components/commonButton/PrimaryButton';

function Treatment() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [createSprintTreatBooking] = useMutation(CREATE_SPRINT_TREAT_BOOKING);
    const [payLaterRequest] = useMutation(PAY_LATER_REQUEST_MUTATION);
    const [modalRemoveShow, setRemoveModalShow] = React.useState(false);
    const { sessionId,couponDiscount, checkedItems, selectedDate, selectedTime, selectedMember, selectedPayment, selectedPaymentOption, activeKey, step, loading, error, searchQuery, navItems, treatmentsData, clinicData, nextAvailableDate, endDate, dates, times, members, depositAmount, paymentAmount,treatmentAmount, checkboxLoaderId, timeLoader, isNextStep, amountToPayNow, remainingAmountToPay, duration, paymentOptions, companyData, bookingId, paymentData, formData, errors, payLaterData,couponDiscountType,couponDiscountUnit,couponCodeName, payLaterMonthlyData } = useSelector((state) => state.treatment);
    const newDate = moment(selectedDate, "dddd, Do MMMM YYYY").format("Do MMMM YYYY");
    const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
    const nameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailRef = useRef(null);
    const sliderRef1 = createRef();
    const [modalShow, setModalShow] = React.useState(false);
    const [isOpenPayment,setIsOpenPayment] = useState(true)
    const [openPayLater,setOpenPayLater] = useState(true)

    const [someNewContentRendered, setSomeNewContentRendered] = useState(false);
    

    useEffect(() => {
        dispatch(resetState());
    }, [])

    useEffect(() => {
        if (!isOpenPayment || !openPayLater) {
            dispatch(resetState());
            navigate("/");
        }
    }, [isOpenPayment, openPayLater]);

    const validateFormData = (formData) => {
        const errors = {};
        if (!formData.name) {
            errors.name = 'First name is required.';
            if (nameRef.current) {
                nameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
        if (!formData.lastName) {
            errors.lastName = 'Last name is required.';
            if (lastNameRef.current) {
                lastNameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
        if (!formData.email) {
            errors.email = 'Email is required.';
            if (emailRef.current) {
                emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email is not valid.';
            if (emailRef.current) {
                emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
        return errors;
    };


    useEffect(() => {
        var amount = checkedItems?.reduce((total, item) => {
            return total + (item?.price || 0); // Add the amount of each item to the total
        }, 0);
    })

    const callPayLa8rApi = () => {
        var depositeRate = 0.1;
        var interestRate = 0.01;
        var amount = checkedItems?.reduce((total, item) => {
            return total + (item?.price || 0); // Add the amount of each item to the total
        }, 0);
        if (amount == 0) {
            amount = 100;
        }
        // Construct the URL for the Payl8r API call
        var url = `https://payl8r.com/get-product-page-calculator/1?username=${PAYL8R_USERNAME}&total=${amount}&depositRate=${depositeRate}&interestRate=${interestRate}`
        
        axios.get(url).then((res) => {
            if (res.data.data) {
                var obj = {
                    "3Month": res.data.data[3],
                    "6Month": res.data.data[6],
                    "9Month": res.data.data[9],
                    "12Month": res.data.data[12],
                    amount: amount
                }
                dispatch(setPayLaterMonthlyData(obj));
            }

        });
    }

    const initiatePayLaterRequest = async (bookingIdData) => {
        try {
            await payLaterRequest({
                variables: {
                    bookingId: bookingIdData
                },
                skip: !bookingId,
                onCompleted: (payLaterData) => {
                    // Update cartItems on success
                    if (payLaterData?.payL8rRequest?.success) {
                        dispatch(setPayLa8rData(payLaterData?.payL8rRequest?.data))
                    }
                    callPayLa8rApi()
                },
                onError: (error) => {
                    dispatch(showToast({ message: error?.message, type: 'error' }));
                }
            });
        } catch (error) {
            console.error('Error creating booking:', error);
            // Handle error (e.g., show a message to the user)
        }
    }

    const handleCompleteFinance = async () => {
        navigate("/")
    }

    const handleChange = async () => {
        if (step == 1 && checkedItems && checkedItems?.length > 0) {
            dispatch(setStep(2));
            dispatch(setIsNextStep(false));
            return;
        } else if (step == 2) {
            dispatch(setIsNextStep(false));
            dispatch(setStep(3));
            return;
        } else if (step == 3) {
            const newErrors = validateFormData(formData);
            if (Object.keys(newErrors).length > 0) {
                dispatch(setErrors(newErrors));
                return; // Stop if there are errors
            }

            try {
                await createSprintTreatBooking({
                    variables: {
                        clinicId: clinicData?.clinic_id,
                        date: selectedDate,
                        time: `${selectedTime}:00`,
                        clientFname: formData?.name,
                        clientLname: formData?.lastName,
                        clientEmail: formData?.email,
                        treatmentIds: checkedItems?.map((item) => (item?.id)),
                        practitionerId: companyData?.clinic_id,
                        payFull: selectedPayment == 1 ? true : false,
                        createdFrom: "na",
                        couponCode:couponCodeName
                    },
                    onCompleted: (bookingData) => {
                        // Update cartItems on success
                        if (bookingData?.createSprintTreatBooking?.success) {
                            dispatch(setBookingId(bookingData?.createSprintTreatBooking?.data?.booking_id))
                            dispatch(setStep(4))
                            if (selectedPaymentOption == "PAY_MONTHLY") {
                                dispatch(setStep(5))
                                initiatePayLaterRequest(bookingData?.createSprintTreatBooking?.data?.booking_id)
                            }

                        } else {
                            dispatch(showToast({ message: bookingData?.createSprintTreatBooking?.message, type: 'error' }));
                        }
                    },
                    onError: (error) => {
                        dispatch(showToast({ message: error?.message, type: 'error' }));
                        console.error("Error saving item:", error?.message);
                    }
                });
            } catch (error) {
                console.error('Error creating booking:', error);
                // Handle error (e.g., show a message to the user)
            }

        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name' || name === 'lastName') {
            const regex = /^[a-zA-Z]*$/;

            if (!regex.test(value)) {
                return;
            }
        }
        const newFormData = { ...formData, [name]: value };
        const newErrors = { ...errors, [name]: '' };

        dispatch(setFormData(newFormData));
        dispatch(setErrors(newErrors));
    };
    const handleTimeClick = (time) => {
        dispatch(setSelectedTime(time));
        dispatch(setIsNextStep(true))
    };



    useQuery(GET_RYFT_PAYMENT_TOKEN_QUERY, {
        variables: { bookingId: bookingId, paymentType: paymentOptions?.is_ryft_setup ? 1 : paymentOptions?.is_stripe_setup ? 2 : 0,redirectUrl: `${frontendURL}`, selectedPayment: selectedPaymentOption  },
        skip: !bookingId,
        onCompleted: (data) => {
            if (data?.getRyftPaymentToken?.success) {
                dispatch(setPaymentData(data?.getRyftPaymentToken?.data))
            }
        },
        onError: (error) => {
            console.error("Error fetching data:", error);
            dispatch(setError(error));
            dispatch(setLoading(false));
        }
    })
    useQuery(GET_BUSINESS_CLINIC_DATA, {
        variables: { userToken: "" },
        skip: step !== 1,
        onCompleted: (data) => {
            if (data?.getListOfBusinessClinics?.success) {
                dispatch(setCompanyData({
                    clinic_id: data?.getListOfBusinessClinics?.data?.clinic_id,
                    company_name: data?.getListOfBusinessClinics?.data?.company_name,
                }));
                dispatch(setClinicData(data?.getListOfBusinessClinics?.data?.items[0]));
                dispatch(setPaymentOptions({
                    is_finance_available: data?.getListOfBusinessClinics?.data?.is_finance_available,
                    is_stripe_setup: data?.getListOfBusinessClinics?.data?.is_stripe_setup,
                    is_ryft_setup: data?.getListOfBusinessClinics?.data?.is_ryft_setup,
                }));
            }

        },
        onError: (error) => {
            console.error("Error fetching clinic data:", error);
            dispatch(setError(error));
            dispatch(setLoading(false));
        }
    });

    useQuery(TREATMENT_LIST, {
        variables: { clinicId: clinicData?.clinic_id },
        fetchPolicy: "network-only",
        skip: !clinicData?.clinic_id,
        onCompleted: (data) => {
            if (data?.getClinicTreatments?.success) {
                const fetchedTreatments = data?.getClinicTreatments?.data?.items;
                if (fetchedTreatments && fetchedTreatments.length > 0) {
                    dispatch(setTreatmentsData(fetchedTreatments));
                    let navDataArr = []
                    fetchedTreatments?.map((navData, index) => {
                        navDataArr.push({ eventKey: navData?.category_name, label: navData?.category_name, category_id: navData?.category_id })
                        if (index == 0) {
                            dispatch(setActiveKey(navData?.category_id));
                        }
                    })
                    dispatch(setNavItems(navDataArr));
                } else {
                    console.log("No treatments found.");
                }
                dispatch(setLoading(false));
            }
        },
        onError: (error) => {
            console.error("Error fetching treatment list data:", error);
            dispatch(setError(error));
            dispatch(setLoading(false));
        }
    });


    const handleCheckboxChange = (id, name, price, deposit, duration) => {
        const sessionId = sessionStorage.getItem('sessionString');
        dispatch(setCheckboxLoaderId(id));
        dispatch(setSelectedPayment(null));

        // Check if the item is already in the cart
        const isChecked = checkedItems.some(item => item.id === id);
        // let cartItemId = cartItems?.filter(data => data?.id == id)
        dispatch(setDuration(0));

        if (isChecked) {
            const updatedItems = checkedItems.filter(item => item.id !== id);
            dispatch(setCheckedItems(updatedItems));
        } else {
            const newItem = { id, name, price, deposit, duration };
            dispatch(setCheckedItems([...checkedItems, newItem]));
            dispatch(setSelectedTime(''))
            dispatch(setIsNextStep(true));
        }
        dispatch(setCheckboxLoaderId(0))
    };

    useEffect(() => {
        if (checkedItems && checkedItems.length > 0) {
            // Resetting values before accumulation
            let totalDuration = 0;
            let totalDeposit = 0;
            let totalPrice = 0;

            checkedItems.forEach(data => {
                totalDuration += data.duration;
                totalDeposit += data.deposit;
                totalPrice += data.price;
            });

            dispatch(setDuration(totalDuration));
            dispatch(setDepositAmount(totalDeposit));
            if(couponDiscount){
                let calCouponDiscount=0
                if(couponDiscountType == "percentage"){
                    calCouponDiscount = (totalPrice * couponDiscountUnit) / 100;
                    dispatch(setCouponDiscount(calCouponDiscount))
                }else{
                    calCouponDiscount = couponDiscountUnit;
                    dispatch(setCouponDiscount(calCouponDiscount))
                }
                dispatch(setPaymentAmount(totalPrice - calCouponDiscount))
            }else{
                dispatch(setPaymentAmount(totalPrice));
            }
            dispatch(setTreatmentAmount(totalPrice));
            dispatch(setIsNextStep(true));
        } else {
            dispatch(setDuration(0));
            dispatch(setDepositAmount(0));
            dispatch(setPaymentAmount(0));
            dispatch(setTreatmentAmount(0));
            dispatch(setIsNextStep(false));
        }
    }, [checkedItems]);


    const handleDateClick = (date) => {
        if (selectedDate != date) {
            dispatch(setSelectedDate(date));
            dispatch(setIsNextStep(false));
            dispatch(setSelectedTime(''));
            dispatch(setTimeLoader(true));
        }
    };


    const handleMemberSelect = (id, name) => {
        dispatch(setSelectedMember({ id: id, name: name }));
    };

    const payment = [
        { id: 1, name: 'Pay in full', desc: 'Pay the full totalAmount now.', type: 'pay_full', isAvailable: true },
        { id: 2, name: 'Pay Deposit', desc: 'depositAmount now, totalAmount on the day.', type: 'pay_deposit', isAvailable: true },
        { id: 3, name: 'Pay Monthly', desc: '3 payments of totalAmount with Payl8r', type: 'pay_monthly', isAvailable: paymentOptions?.is_finance_available },
    ];

    const handlePaymentSelect = (id) => {
        dispatch(setSelectedPayment(id));
        dispatch(setIsNextStep(true))
        if (id == 1) {
            dispatch(setAmountToPayNow(parseFloat(paymentAmount || 0).toFixed(2)))
            dispatch(setRemainingAmountToPay(parseFloat(0).toFixed(2)))
            dispatch(setSelectedPaymentOption("PAY_In_FULL"))
        } else if (id == 2) {
            const validPaymentAmount = parseFloat(paymentAmount) || 0;
            const validDepositAmount = parseFloat(depositAmount) || 0;
            const remainingAmount = Math.max(validPaymentAmount - validDepositAmount, 0);
            dispatch(setAmountToPayNow(parseFloat(validDepositAmount || 0).toFixed(2)))
            dispatch(setRemainingAmountToPay(parseFloat(remainingAmount || 0).toFixed(2)))
            dispatch(setSelectedPaymentOption("PAY_DEPOSIT"))
        } else if (id == 3) {
            const validPaymentAmount = parseFloat(paymentAmount) || 0;
            const monthlyAmount = validPaymentAmount / 3;
            dispatch(setAmountToPayNow(parseFloat(monthlyAmount || 0).toFixed(2)))
            dispatch(setRemainingAmountToPay(parseFloat(validPaymentAmount - monthlyAmount || 0).toFixed(2)))
            dispatch(setSelectedPaymentOption("PAY_MONTHLY"))
        }
    };


    var settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 6.1,
        slidesToScroll: 6,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3.1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 3.1,
                  slidesToScroll: 1,
                  infinite: true,
                }
              }
          ]
    };
    const settings1 = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 4.1,
        slidesToScroll: 4,
        infinite: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false
              }
            }
        ]
    };


    const handleSearchChange = (event) => {
        dispatch(setSearchQuery(event.target.value))
        if (!event.target.value) {
            dispatch(setActiveKey(-1))
        }
    };

    const { data: getNextAvailableDateData, loading: getNextAvailableDateLoading, error: getNextAvailableDateError } = useQuery(GET_NEXT_AVAILABLE_DATE, {
        variables: { clinicId: clinicData?.clinic_id, tokenSug: "" },
        skip: step !== 2,
    });


    useEffect(() => {
        if (getNextAvailableDateData) {
            if (getNextAvailableDateData?.getNextAvailableDateForBookings?.success) {
                const nextAvailableDate = getNextAvailableDateData?.getNextAvailableDateForBookings?.data?.date;
                dispatch(setNextAvailableDate(nextAvailableDate));
                const endDate = new Date(nextAvailableDate);
                endDate.setMonth(endDate.getMonth() + 4); // Move to the next month
                endDate.setDate(0); // Set to the last day of the previous month
                const formattedEndDate = endDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD
                dispatch(setEndDate(formattedEndDate));
            }

        }
    }, [getNextAvailableDateData]);

    const { data: getAvailableDateData, loading: getAvailableDateLoading, error: getAvailableDateError } = useQuery(GET_CLINIC_AVAILABLE_DATE, {
        variables: { clinicId: clinicData?.clinic_id, startDate: nextAvailableDate, endDate: endDate },
        skip: step !== 2 || !nextAvailableDate || !endDate,
    });


    useEffect(() => {
        if (getAvailableDateData) {
            if (getAvailableDateData?.getClinicAvailableDates?.success) {
                const availableDates = getAvailableDateData?.getClinicAvailableDates?.data?.items;

                const formattedDates = availableDates.map(dateString => {
                    const date = new Date(dateString);
                    return {
                        day: date.toLocaleDateString('en-US', { weekday: 'short' }),
                        date: date.getDate().toString().padStart(2, '0'),
                        dateString: dateString,
                    };
                });

                dispatch(setDates(formattedDates));

                if (selectedDate) {
                    dispatch(setSelectedDate(selectedDate));
                } else {
                    dispatch(setSelectedDate(availableDates[0]));
                }
            } else {
                dispatch(setSelectedDate("no-date-available"));
            }
        }
    }, [getAvailableDateData, dispatch]);

    const { data: getTimeSlotData, loading: getTimeSlotDataLoading, error: getTimeSlotDataError } = useQuery(GET_TIME_SLOTES, {
        variables: { clinicId: clinicData?.clinic_id, date: selectedDate, sessionId: sessionId, removePastSlots: true, page: 1, limit: 100, treatmentIds: checkedItems?.map((item) => (item?.id)) },
        skip: step !== 2 || !selectedDate,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (getTimeSlotData) {
            if (getTimeSlotData?.getClinicAvailableTimesForDate.success) {
                let availableTimeSlots = getTimeSlotData?.getClinicAvailableTimesForDate?.data?.items;

                let formattedTimes = availableTimeSlots?.map((timeString) => {
                    return { time: timeString?.time_slot, isBooked: timeString?.isBooked }
                })
                dispatch(setTimes(formattedTimes))
                dispatch(setTimeLoader(false))
            }
        }

    }, [getTimeSlotData])

    if (times && times?.length > 0) {

        const filteredTimes = times.filter((time, index) => {
            if (index === 0) return true; // Always include the first time
            const previousTime = new Date(`1970-01-01T${times[index - 1].time}:00`); // Assuming time is in HH:MM format
            const currentTime = new Date(`1970-01-01T${time.time}:00`);
            const timeDifference = (currentTime - previousTime) / 60000; // Convert milliseconds to minutes

            return timeDifference >= (duration + 15);
        });
    }

    const { data: getTeamMemberData, loading: getTeamMemberDataLoading, error: getTeamMemberDataError } = useQuery(GET_TEAM_MEMBERS, {
        variables: { treatmentId: checkedItems?.map((item) => (item?.id)) },
        skip: step !== 2,
    });

    useEffect(() => {
        if (getTeamMemberData) {
            if (getTeamMemberData?.getAvailableTeamMembers?.success) {

                let availableTeamMembers = getTeamMemberData?.getAvailableTeamMembers?.data?.team_members;

                let membersObj = availableTeamMembers.map((memberData, index) => {
                    if (index == 0) {
                        dispatch(setSelectedMember({ id: memberData?.id, name: memberData?.name }))
                    }
                    return { id: memberData?.id, name: memberData?.name }
                })
                dispatch(setMembers(membersObj))
            }
        }

    }, [getTeamMemberData])

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options); // 'en-GB' for day-month-year format
    };

    const getAvalibleDateMonth = (nextAvailableDate) => {
        const date = new Date(nextAvailableDate);

        // Format the date to "Month Year"
        const options = { year: 'numeric', month: 'long' };
        return date.toLocaleDateString('en-US', options);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (step == 1) {
            if (checkedItems && checkedItems?.length > 0) {
                dispatch(setIsNextStep(true))
            }
            dispatch(setDuration(duration))
        }
        if (step == 2 && selectedTime) {
            dispatch(setIsNextStep(true))
        } else if (step == 3 && selectedPayment) {
            dispatch(setIsNextStep(true))
        }
    }, [step]);
    function calculateEndTime(startTime, durationMinutes) {
        // Split the start time into hours and minutes
        const [hours, minutes] = startTime.split(':').map(Number);

        // Create a Date object for the start time
        const startDate = new Date();
        startDate.setHours(hours);
        startDate.setMinutes(minutes);

        // Add the duration
        const endDate = new Date(startDate.getTime() + durationMinutes * 60000);

        // Format the end time to HH:mm
        const endHours = String(endDate.getHours()).padStart(2, '0');
        const endMinutes = String(endDate.getMinutes()).padStart(2, '0');

        return `${endHours}:${endMinutes}`;
    }

    useEffect(() => {
        const sections = document.querySelectorAll('.treatment-list');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveKey(entry.target.id);
                }
            });
        }, { threshold: 0.5 }); // Adjust the threshold as needed

        sections.forEach(section => {
            observer.observe(section);
        });

        return () => {
            sections.forEach(section => {
                observer.unobserve(section);
            });
        };
    }, []);


    const categoryRefs = useRef([]);
    const sliderRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const categoryId = entry.target.getAttribute('data-category-id');
                    dispatch(setActiveKey(categoryId));

                    const index = navItems.find(item => item.category_id == categoryId);

                    if (sliderRef.current && index?.category_id !== -1) {

                        // Move the slider to the corresponding category
                        sliderRef.current.slickGoTo(index);
                        // Manually scroll the navbar to the selected category
                        scrollToCategoryInNavbar(index);
                    }
                }
            });
        }, { threshold: 0.1 });

        categoryRefs.current.forEach(ref => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [treatmentsData]);


    const scrollToCategoryInNavbar = (index) => {
        const navbar = sliderRef.current?.innerSlider?.list;

        if (navbar) {
            const categoryItem = navbar.children[index?.category_id];
            if (categoryItem) {
                const itemOffset = categoryItem.offsetLeft; // The left position of the selected item
                const itemWidth = categoryItem.offsetWidth; // The width of the selected item
                const visibleWidth = navbar.offsetWidth; // The width of the navbar

                // Scroll only if the item is not in view
                if (itemOffset + itemWidth > navbar.scrollLeft + visibleWidth) {
                    // Scroll to the right (making the item fully visible)
                    navbar.scrollLeft = itemOffset + itemWidth - visibleWidth;
                } else if (itemOffset < navbar.scrollLeft) {
                    // Scroll to the left (making the item fully visible)
                    navbar.scrollLeft = itemOffset;
                }
            }
        }
    };



    const handleSelect = (eventKey) => {
        dispatch(setActiveKey(eventKey))
        const index = navItems.findIndex(item => item.category_id === eventKey);
        if (categoryRefs.current[index]) {
            categoryRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
    };


    const filteredTreatments = treatmentsData?.map((treatmentType) => {
        const filteredTreatmentsForType = treatmentType.treatments?.filter(treatmentData =>
            treatmentData?.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        return {
            ...treatmentType,
            treatments: filteredTreatmentsForType
        };
    })

    const hasMatchingTreatments = filteredTreatments.some(data => data.treatments.length > 0)

    const [slide, setSlide] = useState(0)
    const handleSlickNext = (data) => {

        setSlide(data)
        if (sliderRef1) {
            sliderRef1?.current?.slickNext()
            sliderRef1?.current?.slickGoTo(data)
        }
    }
    const handleSlickPrev = (data) => {
        setSlide(data)
        if (sliderRef1) {
            sliderRef1?.current?.slickPrev()
            sliderRef1?.current?.slickGoTo(data)
        }
    }
    const [sectionRefs, setSectionRefs] = useState([])
    const parentDiv = useRef(null)

    useEffect(() => {
        if (treatmentsData?.length > 0) {
            // Flatten all treatments and create refs for them
            const newSectionRefs = treatmentsData.map((category) =>
                category.treatments.map(() => createRef())
            );
            setSectionRefs(newSectionRefs);
        }
    }, [treatmentsData]);

    const handleChangeCategory = (item) => {
        let categoryItem = document.getElementById(item.category_id.toString());
        if (categoryItem) {
            const parentDiv = document.getElementById('my-list-root'); // Get the parent container for scrolling
            const targetPosition = categoryItem.offsetTop - parentDiv.offsetTop;

            // Get the current scroll position of the parent div
            const currentPosition = parentDiv.scrollTop;

            // Define smooth scroll duration and increment
            const duration = 500; // Milliseconds for the scroll duration
            const startTime = performance.now();

            // Function to handle the smooth scroll animation
            const animateScroll = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / duration, 1); // Progress ratio from 0 to 1

                // Calculate the new scroll position with ease-in-out effect
                const easeInOut = progress < 0.5
                    ? 2 * progress * progress
                    : -1 + (4 - 2 * progress) * progress;

                parentDiv.scrollTop = currentPosition + (targetPosition - currentPosition) * easeInOut;

                // Continue the animation until the scroll reaches the target position
                if (progress < 1) {
                    requestAnimationFrame(animateScroll);
                }
            };

            // Start the smooth scroll animation
            requestAnimationFrame(animateScroll);
        } else {
            console.log('Category item not found');
        }
    };

    const [dimensions, setDimension] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        function handleResize() {
            setDimension({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    var autoScroll;

    if (dimensions?.width <= 576) {
        autoScroll = 1
    } else if (dimensions?.width > 576 && dimensions?.width <= 834) {
        autoScroll = 3
    } else if (dimensions?.width > 834 && dimensions?.width <= 1025) {
        autoScroll = 3
    } else {
        autoScroll = 5
    }

    const [couponCode, setCouponCode] = useState('');
    const [couponErrorMessage, setCouponErrorMessage] = useState(null);
    const [isCallCouponCode, setIsCallCouponCode] = useState(false);
    
    const handlePrimaryButtonClick = () => {
        if (!couponCode) {
            setCouponErrorMessage('Please enter a discount code.');
            return;
        } else {
            setCouponErrorMessage(null);
            setIsCallCouponCode(true);
        }

    };

    const { data: couponData, error: couponError, loading: couponLoading } = useQuery(GET_DISCOUNT, {
        variables: { clinic_id: clinicData?.clinic_id, coupon_code: couponCode },
        skip: !isCallCouponCode,
        onCompleted: (data) => {
            if (data?.getDiscount?.success) {
                let calCouponDiscount=0
                if(data.getDiscount.data?.discount_type == "percentage"){
                    calCouponDiscount = (paymentAmount * data.getDiscount.data?.discount_unit) / 100;
                    dispatch(setCouponDiscount(calCouponDiscount))
                    dispatch(setCouponDiscountUnit(data.getDiscount.data?.discount_unit))
                    dispatch(setCouponDiscountType(data.getDiscount.data?.discount_type))
                }else{
                    calCouponDiscount = data.getDiscount.data?.discount_unit;
                    dispatch(setCouponDiscount(calCouponDiscount))
                    dispatch(setCouponDiscountUnit(data.getDiscount.data?.discount_unit))
                    dispatch(setCouponDiscountType(data.getDiscount.data?.discount_type))
                }
                dispatch(showToast({ message: data?.getDiscount?.message, type: 'success' }));
                dispatch(setPaymentAmount(paymentAmount - calCouponDiscount))
                dispatch(setCouponCodeName(data.getDiscount.data?.discount_name))
                setModalShow(false)
                setIsCallCouponCode(false)
            } else {
                dispatch(showToast({ message: data?.getDiscount?.message, type: 'error' }));
                setIsCallCouponCode(false)
            }
        },
        onError: (error) => {
            dispatch(showToast({ message: error?.message, type: 'error' }));
            setIsCallCouponCode(false)
        },
    });

    const handleRemoveDiscount = () => {
        dispatch(setPaymentAmount(paymentAmount + couponDiscount));  // Restore the original payment amount
        dispatch(setCouponDiscount(0));  // Reset discount
        setCouponCode('');  // Optionally clear coupon code
        setRemoveModalShow(false)
        dispatch(setCouponCodeName(''))
    };

    const onCompletePayment = () => {
        setSomeNewContentRendered(true);
        
    }



    if (loading) {
        return <Loader />
    }

    if (error) {
        return <div>Error fetching data.</div>;
    }

    return (
        <>
            <Header />
            <div className='treatment'>
                <div className='container'>
                    {step == 1 &&
                        <>
                            <div className='pageHeading'>
                                <h2>Treatments</h2>
                                <div className='pageHeadingBox'>
                                    <p onClick={() => { navigate("/") }}>Home</p> <MdOutlineKeyboardArrowRight />  <p>Treatments</p>
                                </div>
                            </div>
                        </>
                    }
                    {step == 2 &&
                        <>
                            <div className='pageHeading'>
                                <h2>Treatments</h2>
                                <div className='pageHeadingBox'>
                                    <p onClick={() => { navigate("/") }}>Home</p> <MdOutlineKeyboardArrowRight />  <p onClick={() => { navigate("/treatment"); dispatch(setStep(1)) }}>Treatments</p> <MdOutlineKeyboardArrowRight />  <p>Book appointment</p>
                                </div>
                            </div>
                        </>
                    }
                    {step == 3 &&
                        <>
                            <div className='pageHeading'>
                                <h2>Treatments</h2>
                                <div className='pageHeadingBox'>
                                    <p onClick={() => { navigate("/") }}>Home</p> <MdOutlineKeyboardArrowRight />  <p onClick={() => { navigate("/treatment"); dispatch(setStep(1)) }}>Treatments</p> <MdOutlineKeyboardArrowRight />  <p onClick={() => dispatch(setStep(2))}>Book appointment</p> <MdOutlineKeyboardArrowRight />  <p>Review details</p>
                                </div>
                            </div>
                        </>
                    }
                    {step == 4 &&
                        <>
                            <div className='pageHeading'>
                                <h2>Treatments</h2>
                                <div className='pageHeadingBox'>
                                    <p onClick={() => { navigate("/") }}>Home</p> <MdOutlineKeyboardArrowRight />  <p onClick={() => { navigate("/treatment"); dispatch(setStep(1)) }}>Treatments</p> <MdOutlineKeyboardArrowRight />  <p onClick={() => dispatch(setStep(2))}>Book appointment</p> <MdOutlineKeyboardArrowRight />  <p>Review details</p>
                                </div>
                            </div>
                        </>
                    }
                    <div className='row'>
                        <div className='col-md-7 col-lg-8'>

                            {step == 1 &&
                                <>
                                    {treatmentsData && treatmentsData.length > 0 && sectionRefs?.length > 0 ?
                                        (<Scrollspy
                                            sectionRefs={sectionRefs}
                                            offset={-100}
                                            rootSelector="#my-list-root"
                                        >
                                            {
                                                ({ currentElementIndexInViewport }) => (
                                                    <>
                                                        {
                                                            currentElementIndexInViewport > slide && currentElementIndexInViewport % autoScroll == 0 && currentElementIndexInViewport != 0 && handleSlickNext(currentElementIndexInViewport)
                                                        }
                                                        {
                                                            currentElementIndexInViewport < slide && handleSlickPrev(currentElementIndexInViewport)
                                                        }
                                                        <div className="page-header custom-slider-treatment-name">
                                                            <Slider {...settings1} ref={sliderRef1}>
                                                                {treatmentsData?.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <div
                                                                                className="categories-tab text-center cursor-pointer"
                                                                                onClick={() => { handleChangeCategory(item, index) }}
                                                                            >
                                                                                <p
                                                                                    className={
                                                                                        currentElementIndexInViewport === index
                                                                                            ? "active"
                                                                                            : ""
                                                                                    }
                                                                                >
                                                                                    {item?.category_name}
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })}
                                                            </Slider>
                                                        </div>
                                                        <div className='search-field'>
                                                            <IoIosSearch />
                                                            <input type='search' placeholder='Search treatments' value={searchQuery} onChange={handleSearchChange} />
                                                        </div>

                                                        <div className='cus-tabcontent' style={{ maxHeight: "500px", overflowY: "auto" }} ref={parentDiv} id="my-list-root">
                                                            {hasMatchingTreatments ? (
                                                                filteredTreatments.map((treatmentType, index) => (
                                                                    <div className='treatment-list' ref={sectionRefs[index]} id={treatmentType.category_id?.toString()}>
                                                                        <div className='treatment-type'>
                                                                            <div className='treatment-heading'>
                                                                                <h5>{treatmentType?.category_name}</h5>
                                                                                <span></span>
                                                                            </div>

                                                                            {treatmentType.treatments?.length > 0 ? (
                                                                                treatmentType.treatments.map(treatment => (
                                                                                    <div
                                                                                        className={`treatment-box ${checkboxLoaderId === treatment.id ? 'checkLoader' : ''}`}
                                                                                        key={treatment?.id}
                                                                                        onMouseDown={(e) => {
                                                                                            if (!e.target.closest('.checkbox-container')) {
                                                                                                if (!checkboxLoaderId || checkboxLoaderId !== treatment.id) {
                                                                                                    handleCheckboxChange(treatment?.id, treatment?.name, treatment?.amount, treatment?.deposit, treatment?.duration);
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        style={{ opacity: checkboxLoaderId === treatment.id ? 0.5 : 1, pointerEvents: checkboxLoaderId === treatment.id ? 'none' : 'auto' }}
                                                                                    >
                                                                                        {checkboxLoaderId === treatment.id && (
                                                                                            <div className="check-loader-container">
                                                                                                <div className="check-loader">
                                                                                                    <div className="check-loader-dot"></div>
                                                                                                    <div className="check-loader-dot"></div>
                                                                                                    <div className="check-loader-dot"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                        <div className='treatment-name'>
                                                                                            <h4>{treatment?.name}</h4>
                                                                                            <div className='checkBoxWrapper'>
                                                                                                <label className="checkbox-container">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={checkedItems.some(item => item.id === treatment.id)}
                                                                                                        onChange={(e) => {
                                                                                                            e.stopPropagation();
                                                                                                            handleCheckboxChange(treatment?.id, treatment?.name, treatment?.amount, treatment?.deposit, treatment?.duration, "abvDataCHECKBOX");
                                                                                                        }}
                                                                                                        className="checkbox-input"
                                                                                                    />
                                                                                                    <span className={`checkbox-custom ${checkedItems.some(item => item.id === treatment.id) ? 'checked' : ''}`}>
                                                                                                        {checkedItems.some(item => item.id === treatment.id) && <span className="checkmark">✓</span>}
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <p>{treatment?.description}</p>
                                                                                        <span className='treatment-price'>{`£${parseFloat(treatment?.amount || 0).toFixed(2)}`}</span>
                                                                                    </div>
                                                                                ))
                                                                            ) : (

                                                                                <div className='treatment-list'>
                                                                                    <div className='treatment-type'>
                                                                                        <div className="treatment-heading">
                                                                                            <h5>No items found in category.</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className='treatment-list'>
                                                                    <div className='treatment-type'>
                                                                        <div className="treatment-heading">
                                                                            <h5>Sorry, no items were found for your search.</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </Scrollspy>) : (
                                            <div>Loading sections...</div>
                                        )
                                    }


                                </>
                            }


                            {step == 2 &&
                                <>
                                    {/* <div className='pageHeading'>
                                        <h2>Treatments</h2>
                                        <div className='pageHeadingBox'>
                                            <p onClick={() => { navigate("/") }}>Home</p> <MdOutlineKeyboardArrowRight />  <p onClick={() => { navigate("/treatment"); dispatch(setStep(1)) }}>Treatments</p> <MdOutlineKeyboardArrowRight />  <p>Book appointment</p>
                                        </div>
                                    </div> */}
                                    <SecHeading HeadingText={'APPOINTMENT DETAILS'} />
                                    {!selectedDate ? (
                                        <div className="check-loader-container">
                                            <div className="check-loader">
                                                <div className="check-loader-dot"></div>
                                                <div className="check-loader-dot"></div>
                                                <div className="check-loader-dot"></div>
                                            </div>
                                        </div>
                                    ) : (
                                        selectedDate !== "no-date-available" ? (
                                            <div className='appointment-details'>
                                                <div className='appointment-date'>
                                                    <div className='date'>
                                                        <h4>{getAvalibleDateMonth(selectedDate)}</h4>
                                                    </div>
                                                    <div className='appointment-slider'>
                                                        <Slider {...settings}>
                                                            {dates.map((date, index) => (
                                                                <div key={index} onClick={() => handleDateClick(date?.dateString)}>
                                                                    <div className={`date-box ${selectedDate === date?.dateString ? 'selected' : ''}`}>
                                                                        <h5>{date.day}</h5>
                                                                        <p>{date.date}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </Slider>
                                                    </div>
                                                </div>
                                                <div className='appointment-time'>
                                                    <SecHeading HeadingText={'Select time'} />
                                                    <div className='select-time'>
                                                        {timeLoader && (
                                                            Array(16)
                                                                .fill(0)
                                                                .map((_, index) => (
                                                                    <div key={index}>
                                                                        <Skeleton className='time-box' />
                                                                    </div>
                                                                ))
                                                        )}
                                                        {!timeLoader && times && times.length > 0 ? (
                                                            times.map((time) => (
                                                                <div
                                                                    key={time.time}
                                                                    className={`time-box ${selectedTime === time?.time ? 'selected' : ''}`}
                                                                    onClick={() => handleTimeClick(time?.time)}
                                                                    aria-disabled={time?.isBooked}
                                                                    style={{ pointerEvents: time?.isBooked ? 'none' : 'auto', opacity: time?.isBooked ? 0.5 : 1 }} // Disable interaction
                                                                >
                                                                    {time?.time}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            !timeLoader && times && times.length === 0 && <h5>No slot available</h5>
                                                        )}
                                                    </div>

                                                </div>
                                                <div className='team-member'>
                                                    <SecHeading HeadingText={'Select team member'} />
                                                    <div className='team-member-list'>
                                                        {members.map((member) => (
                                                            <CustomRadioButton
                                                                key={member.id}
                                                                title={member.name}
                                                                checked={selectedMember?.id === member.id}
                                                                onClick={() => handleMemberSelect(member.id, member?.name)}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='detail-heading'>
                                                <h5>No Dates Available</h5>
                                            </div>
                                        ))
                                    }

                                </>
                            }

                            {step == 3 && <div className='review-payment'>
                                {/* <div className='pageHeading'>
                                    <h2>Treatments</h2>
                                    <div className='pageHeadingBox'>
                                        <p onClick={() => { navigate("/") }}>Home</p> <MdOutlineKeyboardArrowRight />  <p onClick={() => { navigate("/treatment"); dispatch(setStep(1)) }}>Treatments</p> <MdOutlineKeyboardArrowRight />  <p onClick={() => dispatch(setStep(2))}>Book appointment</p> <MdOutlineKeyboardArrowRight />  <p>Review details</p>
                                    </div>
                                </div> */}
                                <SecHeading HeadingText={'REVIEW AND CONFIRM'} />
                                <div className='detail-box d-block d-md-none'>
                                    {clinicData && (<div className='web-detail'>
                                        <div className='web-name'>
                                            <h4>{clinicData?.clinic_name}</h4>
                                            {clinicData?.cln_average_rating && <span><MdStar /> {clinicData?.cln_average_rating} ({clinicData?.total_reviews})</span>}
                                        </div>
                                        <p>{clinicData?.address1}{clinicData?.address2 ? `,${clinicData?.address2}` : ""}</p>
                                    </div>)}
                                    {checkedItems && checkedItems.length > 0 && <div className='treatment-detail'>
                                        <div className='heading-edit'>
                                            <div className='detail-heading'>
                                                <h5>Treatment</h5>
                                                <span></span>
                                            </div>
                                            {step !== 1 && <a onClick={() => dispatch(setStep(1))}>Edit</a>}
                                        </div>
                                        {checkedItems && checkedItems.map((data, index) => (
                                            <div className='sub-detail'>
                                                <h5>{data?.name}</h5>
                                                <p>{`£${parseFloat(data?.price || 0).toFixed(2)}`}</p>
                                            </div>
                                        ))
                                        }
                                    </div>
                                    }
                                    {selectedDate && <div className='appointment-detail'>
                                        {selectedDate && <div className='heading-edit'>
                                            <div className='detail-heading'>
                                                <h5>Appointment details</h5>
                                                <span></span>
                                            </div>
                                            {step !== 2 && checkedItems && checkedItems.length > 0 && <a onClick={() => dispatch(setStep(2))}>Edit</a>}
                                        </div>}
                                        <div className='sub-detail'>
                                            <p>Date</p>
                                            <h5>{formatDate(selectedDate)}</h5>
                                        </div>
                                        {selectedTime && <div className='sub-detail'>
                                            <p>Time</p>
                                            <h5>{selectedTime} - {calculateEndTime(selectedTime, duration)}</h5>
                                        </div>}
                                        <div className='sub-detail'>
                                            <p>Team member</p>
                                            <h5>{selectedMember?.name}</h5>
                                        </div>
                                    </div>}
                                    {treatmentAmount > 0 && <div className='payment-detail'>
                                        <div className='heading-edit'>
                                            <div className='detail-heading'>
                                                <h5>Payment overview</h5>
                                                <span></span>
                                            </div>
                                        </div>
                                        <ul className='sub-detail'>
                                        <li>
                                            <h5>{`${checkedItems.length} Treatment`}</h5>
                                            <p>{treatmentAmount ? `£${parseFloat(treatmentAmount || 0).toFixed(2)}` : '£0.00'}</p>
                                        </li>
                                        {step!=5 ? 
                                        <>
                                        <li>
                                            <h5 onClick={() => couponCodeName ? null : setModalShow(true)} style={{ cursor: `${couponCodeName ? "auto" : "pointer"}`, textDecoration: `${couponCodeName ? "" : "underline"}` }}>Discount</h5>
                                            <p>{couponDiscount ? `£${parseFloat(couponDiscount || 0).toFixed(2)}` : '£0.00'}</p>
                                            
                                        </li>
                                        <li style={{justifyContent:'flex-end'}}>
                                        {couponDiscount > 0 && (
                                            
											<span onClick={() => setRemoveModalShow(true)} style={{cursor: 'pointer',textDecoration:"underline",color: "red"}}>Remove</span>
                                            )}
                                        </li>
                                        </> 
                                        : 
										<>
											<li>
												<h5>Discount</h5>
                                                <p>{couponDiscount ? `£${parseFloat(couponDiscount || 0).toFixed(2)}` : '£0.00'}</p>
											</li>
										</>
                                        }
                                        <li>
                                            <h5>Subtotal</h5>
                                            <p>{paymentAmount ? `£${parseFloat(paymentAmount || 0).toFixed(2)}` : '£0.00'}</p>
                                        </li>
                                        <hr className='custom-hr' />
                                        <li>
                                            <h5>Order total</h5>
                                            <p>{paymentAmount ? `£${parseFloat(paymentAmount || 0).toFixed(2)}` : '£0.00'} </p>
                                        </li>
                                    </ul>
                                    </div>}
                                </div>
                                <div className='review-box'>
                                    <form>
                                        <CustomInputbox
                                            type={'text'}
                                            name={'name'}
                                            placeholder={'First Name'}
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            ref={nameRef} />
                                        {errors.name && <p className='form-validation-error'>{errors.name}</p>}
                                        <CustomInputbox
                                            type={'text'}
                                            name={'lastName'}
                                            placeholder={'Last name'}
                                            value={formData.lastName}
                                            onChange={handleInputChange}
                                            ref={lastNameRef} />
                                        {errors.lastName && <p className='form-validation-error'>{errors.lastName}</p>}
                                        <CustomInputbox
                                            type={'email'}
                                            name={'email'}
                                            placeholder={'Email'}
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            ref={emailRef} />
                                        {errors.email && <p className='form-validation-error'>{errors.email}</p>}
                                    </form>
                                </div>
                                <div className='payment-box'>
                                    <SecHeading HeadingText={'PAYMENT OPTIONS'} />
                                    <div className='payment-option'>
                                        {payment?.filter(data => data.isAvailable).filter(depositeData => depositeData.id == 2 ? depositAmount > 0 ? true : false : true)?.map((payment) => {
                                            let paymentDescription;
                                            if (payment.type == 'pay_full') {
                                                paymentDescription = payment?.desc.replace('totalAmount', `£${parseFloat(paymentAmount || 0).toFixed(2)}`)
                                            } else if (payment.type == 'pay_deposit') {
                                                const validPaymentAmount = parseFloat(paymentAmount) || 0;
                                                const validDepositAmount = parseFloat(depositAmount) || 0;
                                                const remainingAmount = Math.max(validPaymentAmount - validDepositAmount, 0);
                                                paymentDescription = payment?.desc.replace('depositAmount', `£${parseFloat(depositAmount || 0).toFixed(2)}`).replace('totalAmount', `£${parseFloat(remainingAmount || 0).toFixed(2)}`)
                                            } else if (payment.type == 'pay_monthly') {
                                                const validPaymentAmount = parseFloat(paymentAmount) || 0;
                                                const monthlyAmount = validPaymentAmount / 3;
                                                const lastMonthAmount = validPaymentAmount - monthlyAmount * 2;
                                                paymentDescription = payment?.desc.replace('totalAmount', `£${parseFloat(lastMonthAmount || 0).toFixed(2)}`)
                                            }
                                            return (
                                                <CustomRadioButton
                                                    checked={selectedPayment === payment.id}
                                                    key={payment.id}
                                                    onClick={() => handlePaymentSelect(payment.id)}
                                                    title={payment.name}
                                                    description={paymentDescription}
                                                />)
                                        })}
                                    </div>
                                </div>
                            </div>
                            }

                            {step == 4 && <div className='treatment-review-detail'>
                                {/* <div className='pageHeading'>
                                    <h2>Treatments</h2>
                                    <div className='pageHeadingBox'>
                                        <p onClick={() => { navigate("/") }}>Home</p> <MdOutlineKeyboardArrowRight />  <p onClick={() => { navigate("/treatment"); dispatch(setStep(1)) }}>Treatments</p> <MdOutlineKeyboardArrowRight />  <p onClick={() => dispatch(setStep(2))}>Book appointment</p> <MdOutlineKeyboardArrowRight />  <p>Review details</p>
                                    </div>
                                </div> */}
                                <SecHeading HeadingText={'Review details'} />
                                <div className='treatment-review-box'>
                                    <ul>
                                        <li>
                                            <h5>Treatment name:</h5>
                                            <ul>{checkedItems?.map((item) => (<p style={{ padding: 0, border: 'none', display: 'flex' }}>{item?.name}</p>))}</ul>
                                        </li>
                                        <li>
                                            <h5>Treatment price:</h5>
                                            <p>{`£${parseFloat(paymentAmount || 0).toFixed(2)}`}</p>
                                        </li>
                                        <li>
                                            <h5>Date:</h5>
                                            <p>{formatDate(selectedDate)}</p>
                                        </li>
                                        <li>
                                            <h5>Time:</h5>
                                            <p>{selectedTime} - {calculateEndTime(selectedTime, duration)}</p>
                                        </li>
                                        <li>
                                            <h5>Payable Amount :</h5>
                                            <p>{`£${parseFloat(amountToPayNow || 0).toFixed(2)}`}</p>
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className='card-detail-box'>
                                    <label>Card number</label>
                                    <div className='card-field'>
                                        <div className='field-icon'>
                                            <MdOutlinePayment />
                                            <input type='text' placeholder='XXXX XXXX XXXX XXXX' />
                                        </div>
                                        <div className='card-tag'>
                                            <img src={masterCardImg} />
                                            <img src={visaCardImg} />
                                        </div>
                                    </div>
                                </div> */}


                                {paymentData && selectedPaymentOption !== "PAY_MONTHLY" && paymentOptions?.is_ryft_setup ?
                                    <RyftComponent
                                        clientSecret={paymentData?.payment_session}
                                        accountId={paymentData?.accountId}
                                        // successCallback={bookingSuccessCallBack}
                                        setIsOpenPayment={setIsOpenPayment}
                                        // isOpenPayment={isOpenPayment}
                                        amountToPayNow={amountToPayNow}
                                        selectedPaymentOption={selectedPaymentOption}
                                        ryftDataObj={{
                                            date: moment(newDate, ("Do MMMM YYYY")).format('M') == 9 ?
                                                `${moment(newDate, ("Do MMMM YYYY")).format('Do')
                                                } Sept.${moment(newDate, ("Do MMMM YYYY")).format('YYYY')}` :
                                                `${moment(newDate, ("Do MMMM YYYY")).format('Do MMM. YYYY')}`,
                                            treatmentCount: checkedItems?.length,
                                            treatmentPrice: `${amountToPayNow}`,
                                            // totalPrice: selectedPaymentOption === "Split payment" ? `£${totalDeposit?.toFixed(2)}` : `£${getTotalPriceAfterDiscount(2)}`,
                                        }}
                                        bookingId={bookingId}
                                        handleCompleteFinance={handleCompleteFinance}
                                    /> : paymentOptions?.is_stripe_setup ?
                                        <Elements stripe={stripePromise} options={{
                                            clientSecret: paymentData?.payment_session,
                                            // Fully customizable with appearance API.
                                            appearance: {
                                                /*...*/
                                            }
                                        }}>
                                            <CheckoutForm
                                                clientSecret={paymentData?.payment_session}
                                                bookingId={bookingId}
                                                selectedPaymentOption={selectedPaymentOption}
                                                stripeDataObj={{
                                                    date: moment(newDate, ("Do MMMM YYYY")).format('M') == 9 ?
                                                        `${moment(newDate, ("Do MMMM YYYY")).format('Do')
                                                        } Sept.${moment(newDate, ("Do MMMM YYYY")).format('YYYY')}` :
                                                        `${moment(newDate, ("Do MMMM YYYY")).format('Do MMM. YYYY')}`,
                                                    treatmentCount: checkedItems?.length,
                                                    treatmentPrice: amountToPayNow,
                                                    totalPrice: paymentAmount
                                                }}
                                                onCompletePayment={() => {

                                                }}
                                            />
                                        </Elements> : ""
                                }
                                    
                                {/* <button className='secondary-btn' onClick={handleChange}>{`Pay £${amountToPayNow}`} <MdOutlineKeyboardArrowRight /></button> */}
                                
                            </div>
                            }
                            {step == 5 && paymentData && selectedPaymentOption == "PAY_MONTHLY" &&
                                    <div style={{marginTop:"75px"}}>
                                        <PayLater payLaterData={payLaterData} setOpenPayLater={setOpenPayLater}/> 
                                        </div>
                            }
                        </div>
                        <div className='col-md-5 col-lg-4 d-none d-md-block'>
                            <div className='detail-box'>
                                {clinicData && (<div className='web-detail'>
                                    <div className='web-name'>
                                        <h4>{clinicData?.clinic_name}</h4>
                                        {clinicData?.cln_average_rating && <span><MdStar /> {clinicData?.cln_average_rating} ({clinicData?.total_reviews})</span>}
                                    </div>
                                    <p>{clinicData?.address1}{clinicData?.address2 ? `,${clinicData?.address2}` : ""}</p>
                                </div>)}
                                {checkedItems && checkedItems.length > 0 && <div className='treatment-detail'>
                                    <div className='heading-edit'>
                                        <div className='detail-heading'>
                                            <h5>Treatment</h5>
                                            <span></span>
                                        </div>
                                        {step !== 1 && <a onClick={() => dispatch(setStep(1))}>Edit</a>}
                                    </div>
                                    {checkedItems && checkedItems.map((data, index) => (
                                        <div className='sub-detail'>
                                            <h5>{data?.name}</h5>
                                            <p>{`£${parseFloat(data?.price || 0).toFixed(2)}`}</p>
                                        </div>
                                    ))
                                    }
                                </div>
                                }
                                {selectedDate && <div className='appointment-detail'>
                                    {selectedDate && <div className='heading-edit'>
                                        <div className='detail-heading'>
                                            <h5>Appointment details</h5>
                                            <span></span>
                                        </div>
                                        {step !== 2 && checkedItems && checkedItems.length > 0 && <a onClick={() => dispatch(setStep(2))}>Edit</a>}
                                    </div>}
                                    <div className='sub-detail'>
                                        <p>Date</p>
                                        <h5>{formatDate(selectedDate)}</h5>
                                    </div>
                                    {selectedTime && <div className='sub-detail'>
                                        <p>Time</p>
                                        <h5>{selectedTime} - {calculateEndTime(selectedTime, duration)}</h5>
                                    </div>}
                                    <div className='sub-detail'>
                                        <p>Team member</p>
                                        <h5>{selectedMember?.name}</h5>
                                    </div>
                                </div>}
                                {treatmentAmount >0  && <div className='payment-detail'>
                                    <div className='heading-edit'>
                                        <div className='detail-heading'>
                                            <h5>Payment overview</h5>
                                            <span></span>
                                        </div>
                                    </div>
                                    <ul className='sub-detail'>
                                        <li>
                                            <h5>{`${checkedItems.length} Treatment`}</h5>
                                            <p>{treatmentAmount ? `£${parseFloat(treatmentAmount || 0).toFixed(2)}` : '£0.00'}</p>
                                        </li>
                                        {step!=5 ? 
                                        <>
                                        <li>
                                            <h5 onClick={() => couponCodeName ? null : setModalShow(true)} style={{ cursor: `${couponCodeName ? "auto" : "pointer"}`, textDecoration: `${couponCodeName ? "" : "underline"}` }}>Discount</h5>
                                            <p>{couponDiscount ? `£${parseFloat(couponDiscount || 0).toFixed(2)}` : '£0.00'}</p>
                                            
                                        </li>
                                        <li style={{justifyContent:'flex-end'}}>
                                        {couponDiscount > 0 && (
                                            
											<span onClick={() => setRemoveModalShow(true)} style={{cursor: 'pointer',textDecoration:"underline",color: "red"}}>Remove</span>
                                            )}
                                        </li>
                                        </> 
                                        : 
										<>
											<li>
												<h5>Discount</h5>
                                                <p>{couponDiscount ? `£${parseFloat(couponDiscount || 0).toFixed(2)}` : '£0.00'}</p>
											</li>
										</>
                                        }
                                        <li>
                                            <h5>Subtotal</h5>
                                            <p>{paymentAmount ? `£${parseFloat(paymentAmount || 0).toFixed(2)}` : '£0.00'}</p>
                                        </li>
                                        <hr className='custom-hr' />
                                        <li>
                                            <h5>Order total</h5>
                                            <p>{paymentAmount ? `£${parseFloat(paymentAmount || 0).toFixed(2)}` : '£0.00'} </p>
                                        </li>
                                    </ul>
                                </div>}
                                {step !== 4 && step !== 5 &&
                                    <>
                                        <SecondaryButton btnDisabled={isNextStep} onClick={handleChange} btnName={`${step == 3 ? selectedPayment ? `Next ` : 'Select Payment' : 'Next'}`} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='d-block d-md-none mobile-button'>
                        {step !== 4 &&
                            <>
                                <SecondaryButton btnDisabled={isNextStep} onClick={handleChange} btnName={`${step == 3 ? selectedPayment ? `Pay £${amountToPayNow} ` : 'Select Payment' : 'Next'}`} />
                            </>
                        }
                    </div>
                </div>
            </div >
            <Footer />

            <CustomModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                title={"Add discount code"}
                // modalTitle={"Add discount code"}
                className="discount-modal"
            >
                <div className='input-box'>
                    <span>Discount code</span>
                    <CustomInputbox placeholder={"Discount code"} value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                    {couponErrorMessage && <div className="form-validation-error">{couponErrorMessage}</div>}
                    <PrimaryButton btnName={"Save"} onClick={handlePrimaryButtonClick} />
                </div>
            </CustomModal>
            <CustomModal
            show={modalRemoveShow}
            onHide={()=> setRemoveModalShow(false)}
            title={"Are you sure?"}
            // modalTitle={"Add discount code"}
            className="remove-modal"
            >
                <div className='input-box'>
                    <span>Do you really want to remove discount code</span>
                    <PrimaryButton onClick={handleRemoveDiscount} btnName={"Remove"} style={{marginTop: "20px"}} />
                </div>
            </CustomModal>
        </>
    )
}

export default Treatment