import React, { useEffect } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import PageHeading from '../../components/breadcrumbHeading/PageHeading'
// import aboutUsImg from '../../assets/images/aboutUsBanner.png'
import perfectDreamImg from '../../assets/images/perfectDreamImg.png'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import teamImg from '../../assets/images/teamImg.png'
import './meetTeam.scss'
import SecondaryButton from '../../components/commonButton/SecondaryButton'

function MeetTeam() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
          <Header />
          <section className='meet-team-page'>
            <div className='container'>
                <div className='section-title'>
                    <PageHeading pageTitle={"Meet the team"} />
                </div>
                <section className='team'>
                    <div className='team-wrap row'>
                        <div className='box col-md-5'>
                            <img src={teamImg} />
                        </div>
                        <div className='box col-md-7 mt-5 mt-md-0'>
                            <h3>Angelo Vartuca</h3>
                            <span>APM qualified</span>
                            <p>Profhilo Skin Boosters, Dermaplanning, Microneedling, Dermal fillers, Botox, Fat Dissolving, Biotin & B12 injection, Facials, Anatomy & Physiology Level 3 & 4 qualified and fully insured Aesthetic Practitioner, Safety in medicine, First Aid Anaphylaxis & CPR</p>
                        </div>
                    </div>
                </section>
            </div>
                <div className='dream-box'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-md-6'>
                                <h4>Dreaming your perfect look ?</h4>
                                <p>Reserve your spot for a rejuvenating aesthetic treatment</p>
                            </div>
                            <div className='col-md-6 d-flex justify-content-end'>
                                <SecondaryButton btnLink={"treatment"} btnName={"Book a treatment "} />
                            </div>
                        </div>
                    </div>
                </div>
          </section>
          <Footer />
    </>
  )
}

export default MeetTeam
