import React from 'react'
import SecHeading from '../../components/secHeading/SecHeading'
import PageHeading from '../../components/breadcrumbHeading/PageHeading'
import faqImg1 from '../../assets/images/faqImg1.png'
import faqImg2 from '../../assets/images/faqImg2.png'
import faqImg3 from '../../assets/images/faqImg3.png'
import './faq.scss'
import Accordion from 'react-bootstrap/Accordion';
// import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

function FaqInjectables() {

    const accordionData1 = [
        {
            id: 0,
            title: 'How does it work?',
            content: `Anti-Wrinkle treatments relax muscles that are active when our faces are animated. These lines are known as dynamic lines and are caused by frowning, smiling and other daily facial expressions. Over time, dynamic lines cause the loss in collagen in the skin, deepening the lines and turning them into what’s known as static lines. Anti-Wrinkle treatments do not work on static lines, therefore prevention is the key.`
        },
        {
            id: 1,
            title: 'What are the post-care guidelines for anti-wrinkle treatments?',
            content: `You may have some red bumps post-treatment at the injection sites, these should resolve within a period of 1 hour. You must place no pressure on the treatment area for a period of 8 hours post-treatment. You must stay upright for a period of 4 hours post-treatment. Make-up must not be worn for a period of 24 hours post-treatment in the treated area. Avoid alcohol, vigorous exercise, and extreme temperatures for 24 hours post-treatment. If a bruise does occur it may take a few days to appear and can take up to 10 days to resolve. Arnica can be helpful in clearing bruising. You must avoid Ibuprofen and alcohol for 24-48 hours post-treatment as this can increase bruising. Your Anti-Wrinkle Treatment will start to take effect within 1-3 days post-treatment, visible results can be seen on day 7, and full results can be seen on day 14. A feeling of heaviness in the area of treatment can be experienced in the first week post-treatment, this is a result of your Anti-Wrinkle Injections taking effect and your muscles starting to relax. If this is your first Anti-Wrinkle Injections you will be contacted 2 weeks post-treatment when the product has taken full effect to assess your treatment results, a small adjustment may be required at this stage if necessary to perfect results.`
        },
        {
            id: 2,
            title: 'What are the pre-treatment guidelines for Anti-Wrinkle Treatments?',
            content: `Please plan any social occasions around your treatments, as redness, bruising and swelling can be experienced post-treatment. Please let us know if you have any planned travel in the next 4 weeks. You cannot have treatments if you are feeling at all unwell. Please ensure that you have no cold sores (if you are prone to cold sores please discuss with your treating Medical Professional). Are not pregnant/trying to get pregnant/not breastfeeding. You must inform your treating Medical Professional about any medications or dietary supplements you are taking, some medications can adversely affect the way Anti-Wrinkle Injections work and can increase your risk of bruising. You must refrain from taking any painkiller medication that contains Ibuprofen (this only relates to any medication that is not prescribed by a Consultant). Ibuprofen may contribute to bruising and is best avoided for 7 days before treatment. You must refrain from taking Alcohol and Vitamin supplements including; St. John’s Wort, Fish oils, Garlic, Gingko Biloba, and Vitamins A-E, these may all contribute to bruising and are best avoided for 7 days pre-treatment. You must come with no makeup on the day of treatment, you must not reapply makeup for 24 hours in order to reduce the risk of infection or irritation at the injection sites.`
        },
        {
            id: 3,
            title: 'Do I need a consultation for Anti-Wrinkle Injections?',
            content: `If this is your first time to have Anti-Wrinkle Injections carried out, you can book a free consultation appointment. This appointment offers the opportunity to have the treatment carried out, if you wish to do so.`
        },
        {
            id: 4,
            title: 'How long do Anti-Wrinkle Injections last?',
            content: `The longevity of treatment can be affected by your metabolism, bulk muscle and lifestyle. Each patient responds differently, but on average anti-wrinkle injections last between 2 and 4 months before the full relaxing effect on the muscle wears off completely. Anti-wrinkle results wear off gradually, the full relaxation result will last out to 6 weeks post-treatment, after which a tiny bit of movement slowly creeps back in; however muscles are not back to full functionality until the 2 to 4 month mark when re-treatment is recommended.`
        },
        {
            id: 5,
            title: 'Can I have Anti-Wrinkle injections and Dermal filler together?',
            content: `Yes, you can have anti-wrinkle injections and Dermal filler carried out together.`
        },
        {
            id: 6,
            title: 'Is the treatment painful?',
            content: `Anti-Wrinkle Injections have minimal pain, they are administered with very fine needles and take only a few seconds to undertake. It will be a tiny prick of a needle for a moment, most patients tolerate it very well.`
        },
        {
            id: 7,
            title: 'How many areas do I need?',
            content: `This will be based on personal preference. Our medical professionals can advise further based on your preference.`
        },
        {
            id: 8,
            title: 'When will I see results?',
            content: `You will start to notice your results appear 5-7 days post treatment and the full effect should appear after 2 weeks.`
        },
        {
            id: 9,
            title: 'What areas can you get Anti-Wrinkle Injections?',
            content: `In the Aesthetic field, Anti-Wrinkle Injections are used to treat:`,
            listContent: [
                'Horizontal Forehead Lines',
                'Frown Lines – 11 lines between the eyebrows',
                'Crow’s Feet – Around the eyes',
                'Bunny Lines – Scrunch lines on the nose',
            ],
            content1: `As Anti-Wrinkle Injections are prescriptive medications, they are administered by medical professionals. Careful consideration and medical clearance will be undertaken before treatment. The best advice is to visit a highly reputable clinic with highly experienced medical professionals, renowned for good practice and subtle results, such as Skingener8 Clinic.`
        },
        {
            id: 10,
            title: 'What other treatments do you do?',
            content: `The other incredibly popular treatment is Dermal Filler – at Skingener8 Clinic we use the Juvederm brand. Juvederm is a high-end FDA-approved Dermal Filler made in France. It is a Hyaluronic Acid gel which is a naturally occurring substance, it is essentially a sugar gel that is injected into the skin to subtly correct some of the ageing features on the face. It can also be used to naturally enhance lips and to provide subtle contours to the face.`
        },
        {
            id: 11,
            title: 'When can I fly after having Anti-Wrinkle Injections carried out?',
            content: `You can fly 48 hours after having Anti-Wrinkle Injections carried out.`
        },
        {
            id: 12,
            title: 'What are the possible side effects of Anti-Wrinkle Injections?',
            content: `Anti-Wrinkle Injections have a very strong safety profile and have been used for over 30 years in the Aesthetic field with millions of treatments globally every year. They are safe and effective when administered by a highly trained medical Consultant who has a high level of expertise in this field. Most side effects associated with these treatments are mild and transient, they include:`,
            listContent: [
                'Mild swelling at the injection site',
                'Mild tenderness and bruising at or around the injection site',
                'Mild headache',
                'A mild feeling of heaviness',
            ],
        },
        {
            id: 13,
            title: 'How often can I have the treatment carried out?',
            content: `You must wait a minimum of 12 weeks in between your Anti-wrinkle Injection treatments.`
        },
        {
            id: 14,
            title: 'Can I have the treatment while pregnant or breastfeeding?',
            content: `You cannot have any injectable treatment carried out while pregnant or breastfeeding.`
        },
        {
            id: 15,
            title: 'What are Anti-Wrinkle Injections?',
            content: `Anti-wrinkle injections, also known as botulinum toxin injections, are a popular cosmetic procedure used to reduce the appearance of wrinkles and fine lines on the face. The injections work by relaxing the muscles responsible for causing wrinkles which smoothes out the skin's appearance. Anti-wrinkle injections should be administered by a trained healthcare professional in a clinical setting, such as Skingener8 Clinic. Anti-wrinkle injections are commonly used to treat wrinkles and lines in areas such as the forehead, between the eyebrows (frown lines), and around the eyes (crow's feet). They can also be used for other purposes, such as lifting the eyebrows or reducing excessive sweating (hyperhidrosis).`
        },
    ];
    const accordionData2 = [
        {
            id: 0,
            title: 'What is the pre-treatment advice before dermal fillers?',
            content: `Please plan any social occasions around your Injectable treatments as redness, bruising and swelling can be experienced post-treatment. Please let us know if you have any planned travel in the next 4 weeks. You cannot have Injectable treatments if you are feeling at all unwell. If you have had a previous Dermal Filler at another clinic please find out the name of the Dermal Filler that you had before coming for your Dermal Filler treatment with us.`,
            ulContent:`Please ensure that you:`,
            listContent: [
                'Have no cold sores (if you are prone to cold sores please discuss with your treating Medical Professional)',
                'Have had no recent or upcoming invasive dental work a month pre or post-dermal filler treatment',
                'Have overall good dental health',
                'Have had no recent sickness',
                'Have had no recent bacterial infections or antibiotic treatment',
                'Are not pregnant/trying to get pregnant/not breastfeeding',
                'Have had no recent invasive skin treatments (in the same treatment area as your proposed dermal filler treatment area) four weeks pre or post-treatment',
                'Know the name of any previous dermal filler product that you have had injected in the same area previously',
            ],
            content1: 'You must inform your treating Medical Professional about any medications or dietary supplements you are taking, some medications can adversely affect the way Anti-Wrinkle Injections or Dermal Fillers work and can increase your risk of bruising. You must refrain from taking any painkiller medication that contains Ibuprofen (this only relates to any medication that is “not prescribed by a Consultant”) this may contribute to bruising and is best avoided for 7 days pre-treatment. You must refrain from taking Alcohol, and Vitamin supplements including; St. John’s Wort, Fish oils, Garlic, Gingko Biloba, and Vitamins A-E, these may all contribute to bruising and are best avoided for 7 days pre-treatment. It is important that you come with no makeup on the day of treatment, you must not reapply makeup for 24 hours in order to reduce the risk of infection or irritation at the injection sites.'
        },
        {
            id: 1,
            title: 'When can I fly after I have had filler carried out?',
            content: `You can fly 7 days after you have had a Filler treatment.`
        },
        {
            id: 2,
            title: 'When will I see results?',
            content: `Results will be visible immediately.`
        },
        {
            id: 3,
            title: 'Is the treatment painful?',
            content: `You may experience some discomfort during the procedure and some tenderness afterward, though our team is highly experienced so will make every effort to reduce your discomfort.`
        },
        {
            id: 4,
            title: 'How much Dermal Filler do I need?',
            content: `Usually, 1ml of dermal filler is required, but we offer 2ml also. Our professionals will be able to advise on the most suitable amount, depending on your requirements and following a full consultation.`
        },
        {
            id: 5,
            title: 'What should I do after treatment?',
            content: `Your Consultant will advise you fully of post-treatment advice at your consultation. After the treatment, some common injection-related reactions might occur, such as swelling, redness, pain, itching, discoloration, and tenderness at the injection site. These typically resolve spontaneously within 2 to 10 days. The use of anti-inflammatory drugs or aspirin will almost always cause bleeding or increased bruising at the injection site. No exercise except for walking for 24 hours after the procedure.`
        },
        {
            id: 6,
            title: 'How long will the results last?',
            content: `One of the great advantages of Dermal Fillers is that the effects are long-lasting but not permanent. Duration depends on many factors, such as the individual’s skin type, lifestyle, and age. After the initial treatment, follow-up sessions are normally recommended around every 6 to 12 months depending on the individual and the treatment. Treatment of the lips lasts from 3 to 6 months. Your Consultant will give you more information during your consultation.`
        },
        {
            id: 7,
            title: 'Are Dermal Fillers safe?',
            content: `At Skingener8 Clinic, we use the world’s most tried and tested HA dermal fillers available on the market. Its safety and effects are clinically proven and supported by extensive clinical data. Our dermal fillers have been used for over 15 years and in more than 20  million treatments worldwide. Your Consultant  will give you more information during consultation.`
        },
        {
            id: 8,
            title: 'Am I suitable for Dermal Fillers?',
            content: `Do not use Dermal Fillers if you are pregnant, breastfeeding or bruise easily. If you’re unsure of your suitability, our Consultants will advise you further at your consultation.`
        },
        {
            id: 9,
            title: 'What are Dermal Fillers?',
            content: `Dermal Fillers are a non-surgical treatment that will add volume or plump up areas where wrinkles and folds have appeared due to ageing. Dermal Fillers will help to smooth out lines, enhance lips and restore cheeks to give you a more youthful and fresh faced look.`
        },
        {
            id: 10,
            title: 'How long after filler can I be exposed to the sun?',
            content: `We advise waiting 2 weeks before exposing the treated area to the sun or more extreme temperatures.`
        },
        {
            id: 11,
            title: 'What are the possible side effects of Dermal Filler?',
            content: `Most people are able to continue with normal activities immediately post-treatment. Some may have redness, pin prick marks, possibly swelling, possibly bruising – particularly in the lips. Any swelling and bruising may be more apparent the next day.`
        },
    ]
    const accordionData3 = [
        {
            id: 0,
            title: 'What is the post treatment advice for lip fillers?',
            content: `Most people can continue with normal activities immediately post-treatment. Some may have redness, pin prick marks, possibly swelling, possibly bruising – particularly in the lips. Any swelling and bruising may be more apparent the next day.`,
            listContent: [
                'Make-up must not be worn for 24 hours post-treatment.',
                'Avoid alcohol and vigorous exercise for 24 hours post-treatment.',
                'Refrain from placing any pressure on the treatment area for the first-week post-treatment.',
                'Avoid sunbathing, sunbeds, or extremes of heat or cold for 2 weeks post-treatment. These activities have been found to increase and prolong bruising and swelling.',
                'Swelling may worsen in the first 24 hours. Visible swelling should settle within the first-week post-treatment. Cold packs can be helpful in reducing swelling. As a consequence of swelling and internal healing, the product may feel harder or lumpy and you may notice some asymmetry.',
                'The final result may be assessed 4 weeks post-treatment.',
                'Bruising may take a few days to appear and can take up to 10 days to resolve. Arnica can be helpful in clearing bruising. Avoid Ibuprofen and alcohol for 24-48 hours post-treatment to reduce bruising and swelling.',
                'Tenderness should settle as swelling goes down and bruising heals over the course of 4 weeks post-treatment.',
                'Do not have dental work for 4 weeks post Lip Filler treatment.',
                'Do not have any invasive laser or skin treatments in the same area as your Lip Filler treatment for 4 weeks post-treatment.',
                'It is very important that you contact Skingener8 Clinic immediately if you experience intense pain, mottling (fishnet stocking pattern) or discoloration of the skin in the area or the surrounding area of your dermal filler treatment immediately post or in the days post-treatment. Skingener8 Clinic will inform your treating Medical Professional so that they can contact you, advise you and if necessary, arrange to see you again to address and resolve the issue.'
            ],
        },
        {
            id: 1,
            title: 'What is the pre treatment advice for lip filler?',
            content: `Please plan any social occasions around your Injectable treatments as redness, bruising and swelling can be experienced post-treatment.Please let us know if you have any planned travel in the next 4 weeks.`,
            newContent: `You cannot have Injectable treatments if you are feeling at all unwell.If you have had a previous Dermal Filler at another clinic please find out the name of the Dermal Filler that you had before coming for your Dermal Filler treatment with us.`,
            ulContent: `Please ensure that you:`,
            listContent: [
                'Have no cold sores (if you are prone to cold sores please discuss with your treating Medical Professional).',
                'Have had no recent or upcoming invasive dental work a month pre or post-dermal filler treatment.',
                'Have overall good dental health.',
                'Have had no recent sickness.',
                'Have had no recent bacterial infections or antibiotic treatment.',
                'Are not pregnant/trying to get pregnant/not breastfeeding.',
                'Have had no recent invasive skin treatments (in the same treatment area as your proposed dermal filler treatment area) four weeks pre or post-treatment.',
                'Know the name of any previous dermal filler product that you have had injected in the same area previously.',
                'You must inform your treating Medical Professional about any medications or dietary supplements you are taking, some medications can adversely affect the way Anti-Wrinkle Injections or Dermal Fillers work and can increase your risk of bruising.',
                'You must refrain from taking any painkiller medication that contains Ibuprofen (this only relates to any medication that is “not prescribed by a Consultant”) this may contribute to bruising and is best avoided for 7 days pre-treatment.',
                'You must refrain from taking Alcohol, and Vitamin supplements including; St. John’s Wort, Fish oils, Garlic, Gingko Biloba, and Vitamins A-E, these may all contribute to bruising and are best avoided for 7 days pre-treatment.',
                'It is important that you come with no makeup on the day of treatment, you must not reapply makeup for 24 hours in order to reduce the risk of infection or irritation at the injection sites.',
            ],
        },
        {
            id: 2,
            title: 'How long after having lip filler can I be exposed to the sun?',
            content: `We advise waiting 2 weeks before exposing the treated area to the sun or more extreme temperatures.`
        },
        {
            id: 3,
            title: 'When can I fly after I have had lip filler carried out?',
            content: `You can fly 7 days after you have had a Filler treatment.`
        },
        {
            id: 4,
            title: 'How much lip filler do I need?',
            content: `Usually, 1ml of lip filler is required, but we offer 2ml also. Your Consultant  will be able to advise on the most suitable amount, depending on your requirements and following a full consultation.`
        },
        {
            id: 5,
            title: 'How long will the results last?',
            content: `One of the great advantages of Dermal Fillers is that the effects are long-lasting but not permanent. Duration depends on many factors, such as the individual’s skin type, lifestyle, and age. After the initial treatment, follow-up sessions are recommended every 6 to 12 months depending on the individual and the treatment. Treatment of the lips lasts about 6 months. Your Consultant will give you more information during your consultation.`
        },
        {
            id: 6,
            title: 'How long does it last and does it hurt?',
            content: `A huge advantage of lip fillers is that the results are long-lasting but not permanent. Your lips can be altered over time in order to maintain the desired appearance. On average, each treatment lasts 6 months. At Skingener8 Clinic patient care is always at the forefront of all of our procedures and we will ensure that your treatment is as comfortable as possible. All of our lip fillers contain a local anaesthetic however; during the procedure itself, clients may experience some mild discomfort. This is only temporary, and your Consultant will advise you on how to minimise this discomfort.`
        },
        {
            id: 7,
            title: 'How do lip fillers work?',
            content: `Lip fillers also known as dermal fillers are a non-surgical treatment that injects the lips with a substance that is found naturally in your body, in order to achieve volume and plumpness in your lips. This substance also stimulates the growth of collagen giving you a more youthful appearance. At Skingener8 Clinic we use the world’s most tried and tested dermal filler, Juvederm®. This product has been rigorously tested through clinical trials over the past 15 years and its records for safety and efficiency are outstanding. Juvederm® has been used in 20  million treatments worldwide.`
        },
    ]
    const accordionData4 = [
        {
            id: 0,
            title: 'What are Polynucleotides?',
            content: `Polynucleotides are bio-compatible and non-immunogenic molecules derived from DNA. In aesthetic treatments, Polynucleotides are renowned for their regenerative properties, promoting tissue repair, fibroblast stimulation and collagen synthesis.`
        },
        {
            id: 1,
            title: 'What is the difference between Polynucleotides and skin-booster injections?',
            content: `Polynucleotides can hydrate, regenerate, stimulate fibroblasts and collagen production. Skin Boosters despite their ability to hydrate and plump the skin, do not stimulate regeneration of the skin and their results are short-term.`
        },
        {
            id: 2,
            title: 'How do Polynucleotides differ from other aesthetic treatments like fillers or anti-wrinkle injections?',
            content: `Unlike fillers or anti-wrinkle injections that address wrinkles or volume loss, Polynucleotide treatments focus on skin regeneration and quality. Polynucleotides stimulate natural fibroblasts and collagen production, enhancing skin texture, hydration and elasticity over time.`
        },
        {
            id: 3,
            title: 'What skin concerns can Polynucleotide treatments address?',
            content: `Polynucleotides excel in addressing a wide range of concerns, including fine lines, wrinkles, scars, rosacea, inflammation and overall skin quality. They offer a versatile solution suitable for various skin types and concerns.`
        },
        {
            id: 4,
            title: 'How many treatments do I have to do before seeing results?',
            content: `It may take four weeks to begin seeing results, but it will be two months after the second treatment before the most noticeable results are visible.`
        },
        {
            id: 5,
            title: 'How is the Polynucleotide procedure performed?',
            content: `Polynucleotides are administered through micro-injections that are minimally invasive. Our Consultants have been trained by the best to perform this treatment, ensuring comfort and efficacy.`
        },
        {
            id: 6,
            title: 'Is the Polynucleotide treatment painful?',
            content: `Although everyone’s pain threshold varies, pain is quite minimal, thanks to topical numbing cream and fine needles. You may experience mild discomfort during the process.`
        },
        {
            id: 7,
            title: 'How long do the results of Polynucleotide treatments last?',
            content: `Results vary from 6 to 12+ months, but it does depend on the age and the skin concerns being treated.`
        },
        {
            id: 8,
            title: 'Can Polynucleotides be combined with other aesthetic procedures?',
            content: `Yes, Polynucleotides complement nearly all aesthetic treatments, offering a synergistic approach for comprehensive results. Combining treatments enhances overall facial rejuvenation.`
        },
        {
            id: 9,
            title: 'What is the pre-treatment advice before Polynucleotides?',
            content: `To ensure your safety please follow the advice:`,
            listContent: [
                'Be mindful when planning social events because following your Injectable treatment you may have redness, bruising, compromised skin and/or swelling.',
                'Bruising and unwanted side effects can occur at any time even if you have had the treatment done without any concerns prior.',
                'Please let your Consultant Healthcare Professional (HCP) know if you are planning to travel in the next 4 weeks.',
                'You must not have any active cold sores on the day of treatment. If you are prone to cold sores, discuss this with your Consultant at Skingener8 Clinic.',
                'You have no active acne at in the injection area.',
                'You have had no recent illness, including infections or antibiotic treatment.',
                'You are not pregnant, trying to conceive or breastfeeding.',
                'You understand that this is a 3-4 treatment protocol and to receive optimum results you must complete the course of treatments and then maintain throughout the year.',
                'You understand that this treatment does not have instant results and results can take up to 6 weeks to be seen. Results can vary.',
            ],
            content1: 'Inform your HCP of any medications, vitamins and/or supplements you are taking. Many of these thin the blood that can lead to more bruising than normal. If you are taking blood thinning medications like Aspirin or Warfarin, please inform your HCP as these medications can increase bruising. It is important that on the day of treatment you come with no makeup on, you must not reapply makeup for 24 hours to reduce the risk of infection or irritation to the skin.'
        },
        {
            id: 10,
            title: 'What is the post-treatment advice before Polynucleotides?',
            listContent: [
                'Make-up must not be worn for 24 hours post treatment.',
                'Avoid alcohol and exercise for 24 hours post treatment.',
                'Avoid swimming for 48 hours post treatment.',
                'You can wash your face and apply products as normal post treatment but please be gentle for the first 2 weeks.',
                'Avoid sunbathing, sunbeds and/or saunas for 2 weeks post treatment. These activities have been found to increase and prolong bruising and swelling.',
                'Following a treatment the area may swell, this is the body’s natural healing response. The first 1-3 days post treatment are usually the worst.',
                'Bruising may appear immediately or take a few days to appear. Bruising generally resolves within 2 weeks but around the eye area it will take longer as this area has a poor lymphatic system.',
                'Arnica cream or gel may help to reduce bruises faster.',
                'Please avoid taking Ibuprofen and alcohol for 48 hours after treatment, as this can increase bruising.',
                'Always wear a high SPF to protect your skin.',
            ],
            content1: 'Tenderness in the area injected is normal and will settle as the body heals. If you have any general concerns or questions, you can contact Skingener8 Clinic within clinic hours. If you have any life-threatening issues i.e., breathing difficulties and/or anaphylaxis please call 999 immediately.'
        },
    ]
  return (
    <>
    <Header />
    <section className='faq'>
        <div className='container'>
            <PageHeading pageTitle={`Injectable's`} />
            <div className='faq-banner'>
                <div className='row'>
                    <div className='col-md-7'>
                        <div className='box'>
                            <img src={faqImg1} />
                        </div>
                    </div>
                    <div className='col-md-5 mt-4 mt-md-0'>
                        <div className='box'>
                            <img src={faqImg2} />
                            <img src={faqImg3} />
                        </div>
                    </div>
                </div>
                <p>
                Injectable aesthetic treatments are advanced, non-surgical solutions designed to rejuvenate and enhance the appearance of the face. Commonly used injectables include botulinum toxin (Botox), which relaxes facial muscles to reduce the appearance of fine lines and wrinkles, and dermal fillers, which restore volume, contour facial features, and smooth deep creases. These treatments are minimally invasive, offering quick results with little discomfort and no significant recovery time. Ideal for individuals seeking a refreshed, youthful look, injectables provide customizable and natural-looking outcomes tailored to each person's unique facial structure and goals.
                </p>
            </div>
            <SecHeading HeadingText={'FAQ'} />
            <div className='faq-accordion'>
                {/* <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h4>Accordion Item #1</h4> <IoIosArrowDown /></Accordion.Header>
                        <Accordion.Body>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                        </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h4>Accordion Item #1</h4> <IoIosArrowDown /></Accordion.Header>
                        <Accordion.Body>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                        </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> */}

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="pills">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Anti-Wrinkle</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Dermal Filler</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="three">Lip Filler</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="four">Polynucleotides</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <Accordion>
                                {accordionData1.map(item => (
                                    <Accordion.Item eventKey={item.id.toString()} key={item.id}>
                                        <Accordion.Header>
                                            <h4>{item.title}</h4>
                                            {/* <IoIosArrowDown /> */}
                                            <IoIosArrowUp />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>{item.content}</p>
                                            <ul>
                                                <span>{item.ulContent}</span>
                                                {item.listContent && item.listContent.map((liItem, index) => (
                                                    <li key={index}>{liItem}</li>
                                                ))}
                                            </ul>
                                            <p>{item.content1}</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <Accordion>
                                {accordionData2.map(item => (
                                    <Accordion.Item eventKey={item.id.toString()} key={item.id}>
                                        <Accordion.Header>
                                            <h4>{item.title}</h4>
                                            {/* <IoIosArrowDown /> */}
                                            <IoIosArrowUp />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>{item.content}</p>
                                            <ul>
                                                <span>{item.ulContent}</span>
                                                {item.listContent && item.listContent.map((liItem, index) => (
                                                    <li key={index}>{liItem}</li>
                                                ))}
                                            </ul>
                                            <p>{item.content1}</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Tab.Pane>
                        <Tab.Pane eventKey="three">
                            <Accordion>
                                {accordionData3.map(item => (
                                    <Accordion.Item eventKey={item.id.toString()} key={item.id}>
                                        <Accordion.Header>
                                            <h4>{item.title}</h4>
                                            {/* <IoIosArrowDown /> */}
                                            <IoIosArrowUp />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>{item.content}</p>
                                            <p>{item.newContent}</p>
                                            <ul>
                                                <span>{item.ulContent}</span>
                                                {item.listContent && item.listContent.map((liItem, index) => (
                                                    <li key={index}>{liItem}</li>
                                                ))}
                                            </ul>
                                            <p>{item.content1}</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Tab.Pane>
                        <Tab.Pane eventKey="four">
                            <Accordion>
                                {accordionData4.map(item => (
                                    <Accordion.Item eventKey={item.id.toString()} key={item.id}>
                                        <Accordion.Header>
                                            <h4>{item.title}</h4>
                                            {/* <IoIosArrowDown /> */}
                                            <IoIosArrowUp />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>{item.content}</p>
                                            <ul>
                                                <span>{item.ulContent}</span>
                                                {item.listContent && item.listContent.map((liItem, index) => (
                                                    <li key={index}>{liItem}</li>
                                                ))}
                                            </ul>
                                            <p>{item.content1}</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    </section>
    <Footer />
    </>
  )
}

export default FaqInjectables
