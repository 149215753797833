import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import SecHeading from '../../components/secHeading/SecHeading';
import './homePage.scss';
import injectionImg from '../../assets/images/injectionImg.png'
import maskImg from '../../assets/images/maskImg.png'
import needleImg from '../../assets/images/needleImg.png'
import rollerImg from '../../assets/images/rollerImg.png'
import teamImg from '../../assets/images/teamImg.png'
import creamImg from '../../assets/images/creamImg.png'
import facialImg from '../../assets/images/facialImg.png'
import Slider from "react-slick";
import bannerImg1 from '../../assets/images/bannerImg1.png'
import bannerImg2 from '../../assets/images/bannerImg2.png'
import bannerImg3 from '../../assets/images/bannerImg3.png'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import PrimaryButton from '../../components/commonButton/PrimaryButton';
import newCustomerImg from '../../assets/images/new-customer-img.png';

const CATEGORY_LISt = gql`
    query GetProviderCategoryList {
    getProviderCategoryList {
    success
    message
    data {
      categories {
        categoryId
        categoryName
      }
    }
  }
}
`;


function HomePage() {
  const [bgColor, setBgColor] = useState('#f5f5f5');
  const navigate = useNavigate()
  const [categoryList, setCategoryList] = useState([]);
  const settings = {
    beforeChange: (current, next) => {
      // Change the background color based on the slide index (next)
      if (next === 0) {
        setBgColor('#FFF5F7');  // Slide 1 background color
      } else if (next === 1) {
        setBgColor('#FFF4EB');  // Slide 2 background color
      } else if (next === 2) {
        setBgColor('#FFFEE9');  // Slide 3 background color
      }
    },
    dots: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
  };


const staticTreatments = [
  {
      img: injectionImg,
      title: "injectable's",
      description: "Injectables are non-surgical treatments, like Botox and fillers, that reduce wrinkles, add volume, and smooth fine lines for a more youthful look.",
      faq : "/faqInjectables"
  },
  {
      img: maskImg,
      title: "glow peel",
      description: "Glow Peel treatments are advanced skincare procedures designed to rejuvenate skin by removing dead cells, reducing pigmentation, & improving texture.",
      faq : "/"
  },
  {
      img: needleImg,
      title: "microneedling",
      description: "Microneedling is a minimally invasive treatment that stimulates natural skin healing by creating tiny micro-injuries with fine needles.",
      faq : "/faqMicro"
  },
  {
      img: rollerImg,
      title: "facials",
      description: "Facial aesthetics treatments are non-surgical procedures that enhance & rejuvenate the face by smoothing wrinkles, adding volume, and improving texture.",
      faq : "/"
  },
];

  const { data } = useQuery(CATEGORY_LISt, {
    onCompleted: (data) => {
      const categories = data?.getProviderCategoryList?.data?.categories
      if(categories && categories.length>0){
        const treatmentMap = staticTreatments.reduce((acc, treatment, index) => {
          acc[treatment.title.toLowerCase()] = index; // Create a map for ordering
          return acc;
        }, {});
        let categoryArr = categories?.map((category)=>{
          const matchingTreatment = staticTreatments.find(
            (treatment) => treatment.title.toLowerCase() === category.categoryName.toLowerCase()
          );
          return {
            img: matchingTreatment ? matchingTreatment.img : null,
            title: category.categoryName,
            description: matchingTreatment ? matchingTreatment.description : null,
            faq:matchingTreatment ? matchingTreatment.faq : null,
            ...category, // Spread other category data if needed
          };
        }).sort((a, b) => {
          const indexA = treatmentMap[a.title.toLowerCase()] ?? Infinity; // Default to Infinity if not found
          const indexB = treatmentMap[b.title.toLowerCase()] ?? Infinity; // Default to Infinity if not found
          return indexA - indexB; // Sort by the index in treatmentMap
        });
        setCategoryList(categoryArr)
      }
        // setLoading(false);
    },
    onError: (error) => {
        // setError(error);
    }
});

useEffect(() => {
  window.scrollTo(0, 0);
}, []);


  return (
    <>
      <Header />
      <section className='banner' style={{ backgroundColor: bgColor }}>
          <div className="slider-container">
            <Slider {...settings}>
              <div>
                <div className='slider-box row'>
                  <div className='col-md-6'>
                    <div className='box'>
                      <h2>Welcome to our Skincare and Immunity Wellness Clinic</h2>
                      <p>We enhance your beauty and health with personalized treatments, combining skincare expertise with wellness for glowing skin and a strong immune system.</p>
                      <PrimaryButton btnLink={"treatment"} btnName={"Book now"} />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <img src={bannerImg1} />
                  </div>
                </div>  
              </div>
              <div>
                <div className='slider-box row'>
                  <div className='col-md-6 '>
                    <div className='box'>
                      <h2>Instant Glow, Just a Peel Away!</h2>
                      <p>Instantly radiant skin! Our peel-off mask lifts impurities, revealing a fresh glow in one use.</p>
                      <PrimaryButton btnLink={"treatment"} btnName={"Book now"} />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <img src={bannerImg2} />
                  </div>
                </div>  
              </div>
              <div>
                <div className='slider-box row'>
                  <div className='col-md-6'>
                    <div className='box'>
                      <h2>Special offer for new clients</h2>
                      <p>Exclusive Offer for New Customers: £100 Off on Treatments Over £250!</p>
                      <PrimaryButton btnLink={"treatment"} btnName={"Book now"} />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <img src={bannerImg3} />
                  </div>
                </div>  
              </div>
            </Slider>
          </div>
      </section>
      <section className='face-and-body'>
        <div className='container'>
          <SecHeading HeadingText={"FACE AND BODY TREATMENTS"} />
          <div className='row'>
          {/* {categoryList &&  categoryList?.map((category,index)=>{ */}
          {staticTreatments &&  staticTreatments?.map((category,index)=>{
              return(
                <div className='col-md-6' key={index}>
                  <div className='box' onClick={() => {navigate(`${category?.faq}`)}}>
                    <figure>
                      <img src={category?.img} />
                    </figure>
                      {/* <h5>{category?.categoryName}</h5> */}
                      <h5>{category?.title}</h5>
                      <p>{category?.description}</p>
                  </div>
                </div>
              )
            })
            }
          </div>
        </div>
      </section>
      <section className='about-us'>
        <div className='container'>
          <SecHeading HeadingText={"ABOUT US"} />
          <div className='about-us-wrap row'>
            <div className='col-md-7 position-relative'>
              <div className='box'>
                <img src={creamImg} />
              </div>
                <div className='facial-img'>
                  <img src={facialImg} />
                </div>
            </div>
            <div className='col-md-5'>
              <div className='box-2'>
                <p>Skingener8 specializes in personalized care, turning everyday experiences into lasting, positive memories. Join us in enhancing your well-being and creating moments of delight. Your happiness is our priority.</p>
                <PrimaryButton btnLink={"about-us"} btnName={"See more"} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='new-customer-offer'>
          <div className='container'>
              <div className='row align-items-center'>
                  <div className='col-md-5'>
                      <img src={newCustomerImg} />
                  </div>
                  <div className='col-md-7 mt-5 mt-md-0'>
                      <h4>NEW CUSTOMER OFFER</h4>
                      <p>£100 Off treatments over £250</p>
                  </div>
              </div>
          </div>
      </section> */}
      <section className='team'>
        <div className='container'>
          <SecHeading HeadingText={"MEET THE TEAM"} />
          <div className='team-wrap row'>
            <div className='box col-md-5'>
              <img src={teamImg} />
            </div>
            <div className='box col-md-7 mt-5 mt-md-0'>
              <h3>Angelo Vartuca</h3>
              <span>APM qualified</span>
              <p>Profhilo Skin Boosters, Dermaplanning, Microneedling, Dermal fillers, Botox, Fat Dissolving, Biotin & B12 injection, Facials, Anatomy & Physiology Level 3 & 4 qualified and fully insured Aesthetic Practitioner, Safety in medicine, First Aid Anaphylaxis & CPR</p>
              <PrimaryButton btnLink={"meet-the-team"} btnName={"Meet the team"} />
            </div>
          </div>
        </div>
      </section>
      
      <Footer />
    </>

  );
}

export default HomePage;