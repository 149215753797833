// ToastNotification.js
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { hideToast } from '../../store/slices/toastSlice';
import 'react-toastify/dist/ReactToastify.css';

const ToastNotification = () => {
    const dispatch = useDispatch();
    const { message, type, isVisible } = useSelector(state => state.toast);

    useEffect(() => {
        if (isVisible) {
            if (type === 'error') {
                toast.error(message);
            } else {
                toast.success(message);
            }
            dispatch(hideToast()); // Hide toast after showing it
        }
    }, [isVisible, message, type, dispatch]);

    return <ToastContainer position="top-left" />;
};

export default ToastNotification;
