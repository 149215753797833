import React from 'react'
import '../../pages/treatment/treatment.scss'
function CustomRadioButton({key,onClick,title,checked,description}) {
  return (
    <div
    key={key}
    className='team-member-name'
    onClick={onClick}
  >
    <div>
        <h5>{title}</h5>
        {description && <p>{description}</p>}
    </div>
        <input
        type='radio'
        name='teamMember'
        checked={checked}
        onChange={onClick}
        className='radio-input'
        />
        <span className={`radio-custom ${checked ? 'checked' : ''}`}></span>
  </div>
  )
}

export default CustomRadioButton
