import React, { useEffect, useRef, useState } from 'react'
import PageHeading from '../../components/breadcrumbHeading/PageHeading'
import SecHeading from '../../components/secHeading/SecHeading'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import treatmentInfoImg1 from '../../assets/images/treatment-info-1.png'
import treatmentInfoImg2 from '../../assets/images/treatment-info-2.png'
import treatmentInfoImg3 from '../../assets/images/treatment-info-3.png'
import treatmentInfoImg4 from '../../assets/images/treatment-info-4.png'
import treatmentInfoImg5 from '../../assets/images/treatment-info-5.png'
import treatmentInfoImg6 from '../../assets/images/treatment-info-6.png'
import './contactUs.scss'
import SecondaryButton from '../../components/commonButton/SecondaryButton';
import CustomInputbox from '../../components/inputbox/CustomInputbox';
import { gql, useQuery } from '@apollo/client';
import { showToast } from '../../store/slices/toastSlice';
import { useDispatch } from 'react-redux';
function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const dispatch = useDispatch();
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const contactRef = useRef(null);
    const messageRef = useRef(null);

    const CONTACT_US_QUERY = gql`query ContactUs($phone_number: String!, $email: String!, $message: String!, $name: String) {
  contactUs(
    phone_number: $phone_number
    email: $email
    message: $message
    name: $name
  ) {
    success
    message
  }
}
`;

    const [contactFormData, setContactFormData] = useState({
        name: '',
        email: '',
        phone_number: '',
        message: ''

    })
    const [contactFormError, setContactFormError] = useState({})
    const [submitForm, setSubmitForm] = useState(false)


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Validate name (only letters allowed)
        if (name === 'name') {
            const regex = /^[a-zA-Z\s]*$/; // Allow spaces for full names
            if (!regex.test(value)) return;
        }

        // Validate contact number (only numbers)
        if (name === 'phone_number') {
            const regex = /^[0-9]*$/; // Only numbers allowed
            if (!regex.test(value)) return;
        }

        const newFormData = { ...contactFormData, [name]: value };
        const newErrors = { ...contactFormError, [name]: '' };

        setContactFormData(newFormData);
        setContactFormError(newErrors);
    };

    const validateForm = () => {
        let errors = {};
        let firstErrorRef = null;

        if (!contactFormData.name) {
            errors.name = 'Full name is required';
            firstErrorRef = nameRef;
        }
        if (!contactFormData.email) {
            errors.email = 'Email is required';
            if (!firstErrorRef) firstErrorRef = emailRef;
        } else {
            // Check if email matches the regex for a valid email format
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test(contactFormData.email)) {
                errors.email = 'Please enter a valid email address';
                if (!firstErrorRef) firstErrorRef = emailRef;
            }
        }
        if (!contactFormData.phone_number) {
            errors.phone_number = 'Contact number is required';
            if (!firstErrorRef) firstErrorRef = contactRef;
        } else {
            // Check if phone number matches the regex for a valid phone number
            const phoneRegex = /^[0-9]{10,15}$/; // 10-15 digits
            if (!phoneRegex.test(contactFormData.phone_number)) {
                errors.phone_number = 'Please enter a valid phone number';
                if (!firstErrorRef) firstErrorRef = contactRef;
            }
        }
        if (!contactFormData.message) {
            errors.message = 'Message is required';
            if (!firstErrorRef) firstErrorRef = messageRef;
        }

        setContactFormError(errors);

        // Scroll to first invalid field
        if (firstErrorRef) {
            firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        return Object.keys(errors).length === 0;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;
        else setSubmitForm(true)
    }


    useQuery(CONTACT_US_QUERY, {
        variables: {
            name: contactFormData.name,
            phone_number: contactFormData.phone_number,
            email: contactFormData.email,
            message: contactFormData.message,
        },
        skip: !submitForm,
        onCompleted: (data) => {
            if (data?.contactUs?.success) {
                dispatch(showToast({ message: data?.contactUs?.message, type: 'success' }));
                setContactFormData({
                    name: '',
                    email: '',
                    phone_number: '',
                    message: '',
                });
                setSubmitForm(false)
            } else {
                dispatch(showToast({ message: data?.contactUs?.message, type: 'error' }));
            }
        },
        onError: (error) => {
            dispatch(showToast({ message: error?.message, type: 'error' }));
        },
    });

    return (
        <>
            <Header />
            <div className='contact-us'>
                <div className='container'>
                    <PageHeading pageTitle={"Contact us"} />
                    <div className='contact-us-form'>
                        <SecHeading HeadingText={"CONTACT FORM"} />
                        <div className='row form-box'>
                            <div className='col-md-6'>
                                <div className='form-left'>

                                    <form onSubmit={handleSubmit}>
                                        <CustomInputbox
                                            type={'text'}
                                            name={'name'}
                                            placeholder={'Full name'}
                                            value={contactFormData.name}
                                            onChange={handleInputChange}
                                            ref={nameRef} />
                                        {contactFormError.name && <p className='form-validation-error'>{contactFormError.name}</p>}
                                        <CustomInputbox
                                            type={'text'}
                                            name={'email'}
                                            placeholder={'Email'}
                                            value={contactFormData.email}
                                            onChange={handleInputChange}
                                            ref={emailRef} />
                                        {contactFormError.email && <p className='form-validation-error'>{contactFormError.email}</p>}
                                        <CustomInputbox
                                            type={'text'}
                                            name={'phone_number'}
                                            placeholder={'Contact no'}
                                            value={contactFormData.phone_number}
                                            onChange={handleInputChange}
                                            ref={contactRef} />
                                        {contactFormError.phone_number && <p className='form-validation-error'>{contactFormError.phone_number}</p>}
                                        <textarea
                                            name="message"
                                            className="scroll-remove"
                                            placeholder="Ask here"
                                            value={contactFormData.message}
                                            onChange={handleInputChange}
                                            ref={messageRef}
                                        ></textarea>
                                        {contactFormError.message && <p className="form-validation-error">{contactFormError.message}</p>}

                                        <SecondaryButton btnName={"Submit"} />
                                    </form>

                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-right'>
                                    <h4>Get in touch to book</h4>
                                    <div className='box-wrap'>
                                        <h5>Whatsapp/Call</h5>
                                        <a href='https://api.whatsapp.com/send?phone=447534632576' target='_blank'>+44 7534 632576</a>
                                    </div>
                                    <div className='box-wrap'>
                                        <h5>Mail</h5>
                                        <a href='mailto:Service@skingener8.com'>Service@skingener8.com</a>
                                    </div>
                                    <div className='box-wrap'>
                                        <h5>Address</h5>
                                        <p>260 Pentonville Rd, Kings Cross London, N1 9JY</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='follow-us'>
                        <SecHeading HeadingText={'FOLLOW US ON SOCIAL MEDIA FACEBOOK, INSTAGRAM, X ANS TIKTOK'} />
                        <div className='row box-wrap'>
                            <div className='box'>
                                <img src={treatmentInfoImg1} className='w-100' />
                            </div>
                            <div className='box'>
                                <img src={treatmentInfoImg2} className='w-100' />
                            </div>
                            <div className='box'>
                                <img src={treatmentInfoImg3} className='w-100' />
                            </div>
                            <div className='box'>
                                <img src={treatmentInfoImg4} className='w-100' />
                            </div>
                            <div className='box'>
                                <img src={treatmentInfoImg5} className='w-100' />
                            </div>
                            <div className='box'>
                                <img src={treatmentInfoImg6} className='w-100' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs