import React, { useState } from "react";
import {
    useStripe,
    useElements,
    PaymentElement,
    CardElement,
    AddressElement,
    PaymentMethodMessagingElement,
    ElementsConsumer,
} from "@stripe/react-stripe-js";
// import StepButton from "../common/StepButton";
// import { CompletePaymentBooking } from "../redux/actions/completePaymentBooking/CompletePaymentBooking";
import { useNavigate, useParams } from "react-router";
import { ReactComponent as CloseIcon } from '../../assets/cart/close.svg';
import './stripe.scss';
import visaImage from '../../assets/cart/visa.png';
import masterCardImage from '../../assets/cart/mastercard-logo.png';
import { useQuery, gql, useMutation } from '@apollo/client';
import { showToast } from '../../store/slices/toastSlice';
import { useDispatch } from "react-redux";
import { indexPagePattern } from "../../Routes";

const COMPLETE_SPRINT_BOOKING_PAYMENT = gql`mutation CompleteSprintBookingPayment($bookingId: Int!, $paymentId: String,$paymentType: Int, $selectedPayment: completeSprintBookingPaymentSelectedPaymentMethods) {
  completeSprintBookingPayment(booking_id: $bookingId, payment_id: $paymentId,payment_type: $paymentType, selectedPayment: $selectedPayment) {
    success
    message
    data {
      clinic_name
      booking_date
      booking_time
      treatments
      readable_treatment
      capture_id
      transfer_id
      depositScheduledPayouts_id
      consent_form_link
      review_added      
    }
  }
}
`;

const CheckoutForm = ({ clientSecret,  bookingId, setIsOpenPayment, stripeDataObj,isClose = false,selectedPaymentOption }) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const { slug } = useParams()
    const dispatch = useDispatch();

    const [errorMessage, setErrorMessage] = useState(null);
    const [showLoader, setLoader] = useState(false);
    const [completeSprintBookingPayment] = useMutation(COMPLETE_SPRINT_BOOKING_PAYMENT);
    // const { initiateCompletePaymentBooking, completePaymentBookingMutationData } = CompletePaymentBooking()


    const handleSubmitPayment = async (event) => {
        
        // console.log('event: SUBMIT');
        // We don't want to let default form submission happen here,
        // which would refresh the page.

        event.preventDefault();

        // if (!stripe || !elements) {
        //   // Stripe.js has not yet loaded.
        //   // Make sure to disable form submission until Stripe.js has loaded.
        //   return;
        // }

        setErrorMessage("");

        setLoader(true);
        // setProcess("Processing...")

        // console.log('elements.getElement(CardElement): ', elements.getElement(CardElement));
        const { paymentIntent, error } = await stripe.confirmCardPayment(
            clientSecret,
            {
                //`Elements` instance that was used to create the Payment Element
                // elements,
                // confirmParams: {
                //   return_url: window.location.href,
                // },
                payment_method: {
                    card: elements.getElement(CardElement),
                },

                return_url: window.location.href,
            }
        );

        console.log("paymentIntent",paymentIntent)

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            //   setErrorMessage(error.message);
            setErrorMessage(error.message);
            // setProcess("Complete Payment")
            setLoader(false);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            if (
                paymentIntent.status === "succeeded" ||
                paymentIntent.status === "requires_capture"
            ) {
                // setProcess("Complete Payment")
                stripe.createToken(elements.getElement(CardElement)).then(function (result) {
                    if (result.error) {
                        // Handle error
                    } else {
                        var token
                        // Send the token to your server
                        token = result.token;
                        // successCallback(paymentIntent.id, token.id);
                        setLoader(true);
                            completeSprintBookingPayment({
                                variables: {
                                    bookingId:bookingId,
                                    paymentId: paymentIntent.id,
                                    paymentType : 2,
                                    selectedPayment: selectedPaymentOption
                                },
                                onCompleted: (bookingData) => {
                                    // Update cartItems on success
                                    if(bookingData?.completeSprintBookingPayment?.success){
                                        dispatch(showToast({ message: bookingData?.completeSprintBookingPayment?.message, type: 'success' }));
                                        navigate(indexPagePattern());
                                    }else{
                                        dispatch(showToast({ message: bookingData?.completeSprintBookingPayment?.message, type: 'error' }));
                                    }
                                    setLoader(false);
                                    
                                },
                                onError: (error) => {
                                    // showToastMessage(error?.message)
                                    console.error("Error complete booking payment item:", error?.message);
                                    setLoader(false);
                                }
                            });
                            
                        
                        // e.g., save the token to the customer in your database
                    }
                })
            }
        }
    };

    return (
        <>
            <div className="container" >
                {/* {stripeDataObj !== undefined && !isClose && <CloseIcon

                    className="mb-5 cursor-pointer"
                    onClick={() =>
                        setIsOpenPayment(false)}
                />} */}
                <div className={`${stripeDataObj !== undefined ? 'stripe-checkout-main-div' : 'w-100'}`}>


                    
                    <form id='paymentForm' onSubmit={(e) => handleSubmitPayment(e)}>
                        {/* <NewCard/> */}

                        <CardElement
                            className="checkoutcard-field mx-2"
                            options={{
                                hidePostalCode: true,
                                style: {
                                    //your style
                                },
                                classes: {
                                    //your classname

                                }

                            }}
                        />

                        {/* <button disabled={!stripe}>Submit</button> */}
                        {showLoader ? (
                            <p></p>
                        ) : (
                            <>
                                {/* <button type="submit" style={{ visibility: 'none' }} disabled={!stripe || showLoader}>
            Pay Now
          </button> */}
                            </>
                        )}

                        <div className="statics-logos">
                            <div className="statics-logos__item">
                                <img src={visaImage} alt="visa" />
                            </div>
                            <div className="statics-logos__item">
                                <img src={masterCardImage} alt="mastercard" />
                            </div>

                        </div>
                        

                    <button
                    type="submit"
                    className='secondary-btn'
                    blue={true}
                    label={'PAY NOW'}
                    isLoading={showLoader}
                    >{showLoader ? <div className="check-loader-container">
                        <div className="check-loader">
                            <div className="check-loader-dot"></div>
                            <div className="check-loader-dot"></div>
                            <div className="check-loader-dot"></div>
                        </div>
                    </div> :
                    `PAY NOW`}</button>

                        {/* Show error message to your customers */}
                        {errorMessage && <div className="card-invalid-text">{errorMessage}</div>}
                    </form>

                </div>
            </div >
        </>
    );
};

export default CheckoutForm;
