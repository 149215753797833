import React, { forwardRef } from 'react';

const CustomInputbox = forwardRef(({ type, name, placeholder, value, onChange }, ref) => {
  return (
    <input
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      ref={ref}
    />
  );
});

export default CustomInputbox;
