// src/graphql/queries.js
import { gql } from '@apollo/client';

export const GET_BUSINESS_CLINIC_DATA = gql`
query GetListOfBusinessClinics($userToken: String!) {
  getListOfBusinessClinics(user_token: $userToken) {
    success
    message
    data {
      company_name
      clinic_id
      is_finance_available
      is_stripe_setup
      is_ryft_setup
      clinic_DB_id
      clinic_url
      total
      items {
        clinic_id
        clinic_status
        next_step
        clinic_name
        address1
        address2
        image_url
        city
        postcode
        cln_average_rating
        total_reviews
        complete_address
        clinic_slug
      }
    }
  }
}
`;

export const TREATMENT_LIST = gql`
query GetClinicTreatments($clinicId: Int!) {
  getClinicTreatments(clinic_id: $clinicId) {
    success
    message
    data {
      treatment_DB_id
      items {
        category_name
        category_id
        treatments {
          id
          name
          amount
          image_url
          deposit
          slug
          duration
          description
          average_review_rating
          total_review_count
        }
      }
    }
  }
}
`;

export const GET_NEXT_AVAILABLE_DATE = gql`
query GetNextAvailableDateForBookings($tokenSug: String!, $clinicId: Int!) {
    getNextAvailableDateForBookings(tokenSug: $tokenSug, clinicId: $clinicId) {
      success
      message
      data {
        date
      }
    }
}
`;

export const GET_CLINIC_AVAILABLE_DATE = gql`
query GetClinicAvailableDates($clinicId: Int!, $startDate: String!, $endDate: String!) {
    getClinicAvailableDates(clinic_id: $clinicId, start_date: $startDate, end_date: $endDate) {
      success
      message
      data {
        items
        total
      }
    }
}
`;

export const GET_TIME_SLOTES = gql`
query GetClinicAvailableTimesForDate($clinicId: Int!, $date: String!, $sessionId: String!, $treatmentIds: [Int], $page: Int, $limit: Int, $removePastSlots: Boolean) {
  getClinicAvailableTimesForDate(clinic_id: $clinicId, date: $date, session_id: $sessionId, treatment_ids: $treatmentIds, page: $page, limit: $limit, removePastSlots: $removePastSlots) {
    success
    message
    data {
      items {
        time_slot
        isBooked
      }
      businessHours {
        daysOfWeek
        startTime
        endTime
      }
      total
    }
  }
}
`;

export const GET_TEAM_MEMBERS = gql`
query GetAvailableTeamMembers($treatmentId: [Int]!) {
  getAvailableTeamMembers(treatment_id: $treatmentId) {
    success
    message
    data {
      team_members {
        id
        name
        image
        initials
        occupations
      }
    }
  }
}
`;

export const CREATE_SPRINT_TREAT_BOOKING = gql`
mutation CreateSprintTreatBooking($date: String!, $time: String!, $clientFname: String!, $clientLname: String!, $clientEmail: String!, $treatmentIds: [Int], $endTime: String, $practitionerId: Int, $payFull: Boolean, $clinicId: Int, $createdFrom: String,$couponCode: String) {
  createSprintTreatBooking(date: $date, time: $time, client_fname: $clientFname, client_lname: $clientLname, client_email: $clientEmail, treatmentIds: $treatmentIds, end_time: $endTime, practitioner_id: $practitionerId, pay_full: $payFull, clinic_id: $clinicId, created_from: $createdFrom,coupon_code: $couponCode) {
    success
    message
    data {
      booking_id
      treatmentDetails {
        clinic_name
        booking_date
        booking_time
        treatments
        readable_treatment
      }
      saved_cards {
        card_number
        card_exp_month
        card_exp_year
        saved_card_id
      }
    }
  }
}
`;

export const GET_RYFT_PAYMENT_TOKEN_QUERY = gql`
query GetRyftPaymentToken($bookingId: Int, $paymentType: Int,$redirectUrl: String, $selectedPayment: getRyftPaymentTokenPaymentMethods) {
  getRyftPaymentToken(booking_id: $bookingId, payment_type: $paymentType,redirectURL: $redirectUrl, selectedPayment: $selectedPayment) {
    success
    message
    data {
      payment_session
      accountId
    }
  }
}
`;

export const PAY_LATER_REQUEST_MUTATION = gql`
mutation PayL8rRequest($bookingId: Int!, $redirectUrl: String, $typeData: String, $cfrToken: String) {
  payL8rRequest(booking_id: $bookingId, redirect_url: $redirectUrl, typeData: $typeData, cfrToken: $cfrToken) {
    success
    message
    data {
      encodedData
      item_data
      rid
      consent_form_link
    }
  }
}
`;

export const GET_DISCOUNT = gql`
  query GetDiscount($clinic_id: Int!, $coupon_code: String!) {
    getDiscount(clinic_id: $clinic_id, coupon_code: $coupon_code) {
      success
      message
      data {
        discount_name
        discount_type
        discount_unit
      }
    }
  }
`;