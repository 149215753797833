import React, { useEffect, useState } from 'react';
import { config } from '../../config';
// import { ReactComponent as CloseIcon } from '../../images/close_icon.svg';
// import StepButton from '../common/StepButton';
import './ryft-style.scss';
import visaImage from '../../assets/cart/visa.png';
import masterCardImage from '../../assets/cart/mastercard-logo.png';
import { toast } from 'react-toastify';
import { Button } from 'bootstrap';
// import { CompleteForwardConsentFormPaymentBooking } from '../redux/actions/forward-consent-form/ForwardConsentFormBooking';
// import { SaveForwardConsentFormMutation } from '../redux/actions/forward-consent-form/saveForwardConsentForm';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { showToast } from '../../store/slices/toastSlice';
const COMPLETE_SPRINT_BOOKING_PAYMENT = gql`mutation CompleteSprintBookingPayment($bookingId: Int!, $paymentId: String,$selectedPayment: completeSprintBookingPaymentSelectedPaymentMethods,$paymentType: Int) {
  completeSprintBookingPayment(booking_id: $bookingId, payment_id: $paymentId,selectedPayment: $selectedPayment,payment_type: $paymentType) {
    success
    message
    data {
      clinic_name
      booking_date
      booking_time
      treatments
      readable_treatment
      capture_id
      transfer_id
      depositScheduledPayouts_id
      consent_form_link
      review_added    
    }
  }
}
`;
const RyftComponent = ({
    clientSecret,
    successCallback,
    failureCallback,
    paymentType,
    isOpenPayment,
    setIsOpenPayment,
    accountId,
    ryftDataObj,
    isForwardConsentFormPayment = false,
    setStep,
    noShowPolicy = false,
    bookingId,
    selectedOption,
    note,
    selectedPaymentOption,
    handleCompleteFinance,
    amountToPayNow
}) => {
    const [paymentStateTime, setPaymentStartTime] = useState(null);

    const [ryftErrorMessage, setRyftErrorMessage] = React.useState('');

    const [loader, setLoader] = useState(false);
    const [completeSprintBookingPayment] = useMutation(COMPLETE_SPRINT_BOOKING_PAYMENT);
    const dispatch = useDispatch();
    // const { initiateCompleteForwardConsentFormPaymentBooking, completeForwardConsentFormPaymentBookingMutationData } = CompleteForwardConsentFormPaymentBooking()
    // const searchParams = new URLSearchParams(window.location.search)
    // const { initiateSaveForwardConsentForm } = SaveForwardConsentFormMutation()
    //===========================*********NOTE:PLEASE UNCOMMENT CLIENTSECRET===========================*********
    // if (!clientSecret) {
    //     return;
    // }
    const handlePaymentSubmit = async (e) => {
        // e.preventDefault()
        // try {
        //     let response = await window.paymentResponseCallback()
        //     successCallback(response)
        //    // alert("succeess")

        // } catch (e) {
        //     //alert("error")
        // }
        let Ryft = window.Ryft;
        const payButton = document.getElementById('ryft-pay-btn');
        payButton.disabled = true;
        setLoader(true);
        setRyftErrorMessage('');
        let errorMessage =
            "<div style='color:red;'>There seems to be an issue with the payment, if using through the app, please try through the faces website within your account. <br><br>If still having issues, please contact <a href='mailto:support@facesconsent.com'>support@facesconsent.com</a> or call <a href='tel:01785558018'>01785 5580 18</a>.</div>";
        Ryft.attemptPayment()
            .then((paymentSession) => {
                if (paymentSession.status === 'Approved' || paymentSession.status === 'Captured') {
                    var redirectUrl = paymentSession.returnUrl,
                        paymentSessionId = paymentSession.id;
                    redirectUrl += '&ps=' + paymentSessionId;
                    redirectUrl += '&paymentType=' + selectedPaymentOption;
                    completeSprintBookingPayment({
                        variables: {
                            bookingId:bookingId,
                            paymentId: paymentSession.id,
                            selectedPayment : selectedPaymentOption,
                            paymentType:1
                        },
                        onCompleted: (bookingData) => {
                            // Update cartItems on success
                            if(bookingData?.completeSprintBookingPayment?.success){
                                dispatch(showToast({ message: bookingData?.completeSprintBookingPayment?.message, type: 'success' }));
                                if(handleCompleteFinance){
                                    handleCompleteFinance();
                                }
                            }else{
                                dispatch(showToast({ message: bookingData?.completeSprintBookingPayment?.message, type: 'error' }));
                                if(handleCompleteFinance){
                                    handleCompleteFinance();
                                }
                            }
                            setLoader(false);
                        },
                        onError: (error) => {
                            // showToastMessage(error?.message)
                            setLoader(false);
                            console.error("Error saving item:", error?.message);
                            if(handleCompleteFinance){
                                handleCompleteFinance();
                            }
                        }
                    });
                    return;
                }
                if (paymentSession.lastError) {
                    payButton.disabled = false;
                    setLoader(false);
                    const userFacingError = Ryft.getUserFacingErrorMessage(
                        paymentSession.lastError
                    );
                    // Show userFacingError to customer
                    setRyftErrorMessage(errorMessage);
                }
            })
            .catch((error) => {
                payButton.disabled = false;
                setLoader(false);
                if (error) {
                    setRyftErrorMessage(errorMessage);
                } else {
                    setRyftErrorMessage(errorMessage);
                }
                // Show error to customer
            });
        e.preventDefault();
    };

    useEffect(() => {
        if (clientSecret) {
            let Ryft = window.Ryft;
            let initializationValues = {
                
                publicKey: config.RYFT_PUBLIC_KEY,
                clientSecret: clientSecret,
                accountId: accountId,
                applePay: {
                    merchantName: 'Faces',
                    merchantCountryCode: 'GB',
                },
            };
            Ryft.init(initializationValues);

            Ryft.addEventHandler('walletPaymentSessionResult', (e) => {
                const paymentSession = e.paymentSession;
                setRyftErrorMessage('');
                if (paymentSession.status === 'Approved' || paymentSession === 'Captured') {
                    // payment successful, show the customer your order success page
                    return;
                }
                if (paymentSession.lastError) {
                    const userFacingError = Ryft.getUserFacingErrorMessage(
                        paymentSession.lastError
                    );
                    // Show userFacingError to customer
                }
            });

            Ryft.addEventHandler('cardValidationChanged', (e) => {
                const payButton = document.getElementById('ryft-pay-btn');
                setRyftErrorMessage('');
                payButton.disabled = !e.isValid;
            });

            Ryft.addEventHandler('paymentMethodSelectionChanged', (e) => {
                const payButton = document.getElementById('ryft-pay-btn');
                setRyftErrorMessage('');
                payButton.disabled = !e.paymentMethod;
            });
        }
    }, [clientSecret]);

    useEffect(() => {
        // setPaymentStartTime(moment());
        let timeoutObj = setTimeout(() => {
            alert(
                'Sorry but you have not completed your process in the predefined time. Please start the payment process again!'
            );
            // console.log("show toast error");
            setIsOpenPayment(false)
        }, 60 * 60 * 1000);

        return () => {
            clearTimeout(timeoutObj);
        };
    }, []);

    return (
        <div className='container'>
            {/* {
            !isForwardConsentFormPayment  && <CloseIcon className='cursor-pointer mb-5' onClick={() => setIsOpenPayment(false)} />
           } */}
            {isForwardConsentFormPayment &&
                <div className='consent-form-ryft-payment-card-section'>
                    <div className='treatment-payment-section'>

                        {ryftDataObj?.payableAmount && <div className='payment-card'>
                            <b>Total to pay</b>
                            <p>{ryftDataObj?.payableAmount}</p>
                        </div>}
                    </div>
                </div>
            }
            <div className={`d-flex align-items-center justify-content-center flex-column ${isForwardConsentFormPayment ? 'ryft-checkout-main-consent-form' : 'ryft-checkout-main'}`}>

                {
                    !isForwardConsentFormPayment &&
                    //        <div className='ryft-header-section pb-3'>
                    //       <div className='detail-section'>
                    //           <h1>Date :</h1>
                    //           <p>{ryftDataObj?.date}</p>
                    //       </div>
                    //       <div className='detail-section'>
                    //           <h1> Treatment price:</h1>
                    //           <p>{ryftDataObj?.treatmentPrice}</p>
                    //       </div>
                    //       <div className='detail-section'>
                    //           <h1>Payable Amount:</h1>
                    //           <p>{ryftDataObj?.totalPrice}</p>
                    //       </div>
                    //   </div>
                    <div className='consent-form-ryft-payment-card-section-booking-link pb-3'>
                        <div className='treatment-payment-section-booking-link'>

                            {ryftDataObj?.totalPrice && <div className='payment-card-booking-link'>
                                <b>Payable Amount</b>
                                <p>{ryftDataObj?.totalPrice}</p>
                            </div>}
                        </div>
                    </div>
                }

                <form id="ryft-pay-form" className='ryft-pay-form'
                    onSubmit={handlePaymentSubmit}
                >

                    <div className="statics-logos">
                        <div className="statics-logos__item">
                            <img src={visaImage} alt="visa" />
                        </div>
                        <div className="statics-logos__item">
                            <img src={masterCardImage} alt="mastercard" />
                        </div>
                    </div>

                    {
                        noShowPolicy && isForwardConsentFormPayment &&
                        <div className='no-show-policy-section'>
                            <h1>No show and cancellation policy</h1>
                            <p>Card details are securely stored. No upfront charge is made. Details could be billed depending on the businesses no show policy.</p>
                        </div>
                    }
                    <div>

                    <button
                    className='secondary-btn'
                    id="ryft-pay-btn"
                    blue={true}
                    label={'PAY NOW'}
                    isLoading={loader}
                    >
                        {loader ? <div className="check-loader-container">
                                            <div className="check-loader">
                                                <div className="check-loader-dot"></div>
                                                <div className="check-loader-dot"></div>
                                                <div className="check-loader-dot"></div>
                                            </div>
                                        </div> :
                                        `Pay £${amountToPayNow}`}</button>
                    </div>
                    <div
                        id="ryft-pay-error"
                        dangerouslySetInnerHTML={{ __html: ryftErrorMessage }}
                    ></div>
                </form >
                <div className="card-icon-"></div>
            </div >
        </div >
    );
};

export default RyftComponent;
