import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PrimaryButton from '../commonButton/PrimaryButton';
import './customModal.scss'
import CustomInputbox from '../inputbox/CustomInputbox';
import { RxCross2 } from "react-icons/rx";

function CustomModal(props) {
  return (
    <>
        <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={props.className}
        >
        <Modal.Header className='justify-content-between'>
            <Modal.Title id="contained-modal-title-vcenter">
            {props?.title}
            </Modal.Title>
            <RxCross2 style={{cursor: "pointer"}} onClick={props.onHide} />
        </Modal.Header>
        <Modal.Body>
        {props.children}
        </Modal.Body>
        </Modal>
    </>
  )
}

export default CustomModal
