import './loader.scss'
function Loader() {
    return (
                <div className="LoaderMain">
                    <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
    );
}

export default Loader