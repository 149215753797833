import React, { useEffect } from 'react'
import SecHeading from '../../components/secHeading/SecHeading'
import './termandcondition.scss'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import PageHeading from '../../components/breadcrumbHeading/PageHeading'

function TermAndCondition() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  return (
    <>
    <Header />
    <section className='term-and-condition'>
            <div className='container'>
                <div className='term-and-condition-wrap'>
                    <PageHeading pageTitle={'Terms and conditions'} />
                    <div className='term-and-condition-box'>
                        <h4>Introduction</h4>
                        <p>These terms and conditions govern the booking of our services. By making a booking, you agree to be bound by these terms.</p>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Booking and Payments</h4>
                        <ul>
                            <li>Bookings can be made via Whatsapp, DM or Phone call (availability evenings Weekday and Weekends only)</li>
                            <li>A deposit, known as a booking fee, is required at the time of booking. This fee is non-refundable.</li>
                            <li>The balance must be paid by appointment before the service is provided.</li>
                            <li>Prices are subject to change, but changes will not affect bookings already confirmed.</li> 
                        </ul>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Cancellation Policy</h4>
                        <ul>
                            <li>Cancellations made anytime 24 hours before the appointment will incur booking fee. No-shows will be blacklisted from any future request.</li>
                            <li>We reserve the right to cancel or reschedule appointments due to unforeseen circumstances.</li>
                        </ul>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Changes to Appointments</h4>
                        <ul>
                            <li>We endeavour to avoid any changes to confirmed bookings. However, in exceptional cases, we may need to make changes. We will inform you as soon as possible and offer a rescheduled time.</li>
                        </ul>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Consent to Treatments</h4>
                        <ul>
                            <li>By consenting to a treatment, you acknowledge the inherent risks and agree that we will not be held liable for the outcomes.</li>
                            <li>We commit to supporting and addressing any concerns arising from the service provided.</li>
                        </ul>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Client Responsibilities</h4>
                        <ul>
                            <li>Please arrive on time for your appointment. Late arrivals may result in reduced service time or rescheduling.</li>
                            <li>Provide accurate and complete information when booking.</li>
                        </ul>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Service Provider Responsibilities</h4>
                        <ul>
                            <li>We commit to providing services to the best of our ability and maintaining a safe environment for our clients.</li>
                        </ul>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Data Protection and Privacy</h4>
                        <ul>
                            <li>We adhere to data protection laws in handling personal information. Your information will be used only for providing and improving our services.</li>
                        </ul>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Limitation of Liability</h4>
                        <ul>
                            <li>Our liability for any issues arising from our services is limited to the service fee paid.</li>
                            <li>We are not liable for indirect or consequential losses.</li>
                        </ul>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Force Majeure</h4>
                        <ul>
                            <li>We are not responsible for any failure to perform due to circumstances beyond our control (e.g., natural disasters, pandemics).</li>
                        </ul>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Dispute Resolution</h4>
                        <ul>
                            <li>Any disputes arising from these terms will be resolved through negotiation and, if necessary, mediation.</li>
                        </ul>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Contact Information</h4>
                        <ul>
                            <li>For inquiries or complaints, please contact us at service@skingenerate.com.</li>
                        </ul>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Amendments</h4>
                        <ul>
                            <li>We reserve the right to amend these terms and conditions. Any changes will be communicated to you.</li>
                        </ul>
                    </div>
                    <div className='term-and-condition-box'>
                        <h4>Governing Law</h4>
                        <ul>
                            <li>These terms are governed by the Medical Safety Act.</li>
                        </ul>
                    </div>
                </div>
            </div>
    </section>
    <Footer />
    </>
  )
}

export default TermAndCondition
