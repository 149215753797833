import React from 'react'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from 'react-router-dom'

function SecondaryButton({btnLink, btnName,styleClass,onClick,btnDisabled=true}) {
  const navigate = useNavigate()
return (
  <>
      <button aria-disabled={btnDisabled} style={{ pointerEvents: btnDisabled ? 'auto' : 'none', opacity: btnDisabled ? 1 : 0.5 }}   className='secondary-btn' onClick={btnLink ? () => {navigate(`/${btnLink}`)} : onClick}>{btnName} <MdOutlineKeyboardArrowRight /></button>
  </>
)
}

export default SecondaryButton
