import React from 'react'
import './secHeading.scss'

function SecHeading({HeadingText}) {
  return (
    <>
    <div className='secTitle'>
        <h2>{HeadingText}</h2>
        <div></div>
    </div>
    </>
  )
}

export default SecHeading
