import { generatePath, matchPath } from "react-router"

export const indexPage = "/"
export const indexPagePattern = () => {
  return generatePath(indexPage);
}

export const aboutUsPage = "/about-us"
export const aboutUsPagePattern = () => {
  return generatePath(aboutUsPage);
}

export const meetTeamPage = "/meet-the-team"
export const meetTeamPagePattern = () => {
  return generatePath(meetTeamPage);
}

export const contactUsPage = "/contact-us"
export const contactUsPagePattern = () => {
  return generatePath(contactUsPage);
}
export const treatmentPage = "/treatment"
export const treatmentPagePattern = () => {
  return generatePath(treatmentPage);
}
export const termAndConditionPage = "/term-and-condition"
export const termAndConditionPagePattern = () => {
  return generatePath(termAndConditionPage);
}
export const faqPage = "/faqInjectables"
export const faqPagePattern = () => {
  return generatePath(faqPage);
}
export const faqMicroPage = "/faqMicro"
export const faqMicroPagePattern = () => {
  return generatePath(faqMicroPage);
}