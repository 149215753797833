import React, { useEffect } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import PageHeading from '../../components/breadcrumbHeading/PageHeading'
import aboutUsImg1 from '../../assets/images/aboutUsBanner1.png'
import aboutUsImg2 from '../../assets/images/aboutUsBanner2.png'
import aboutUsImg3 from '../../assets/images/aboutUsBanner3.png'
import aboutUsImg4 from '../../assets/images/aboutUsBanner4.png'
import aboutUsImg5 from '../../assets/images/aboutUsBanner5.png'
import perfectDreamImg from '../../assets/images/perfectDreamImg.png'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import './aboutUs.scss'
import Slider from 'react-slick'
import { useNavigate } from 'react-router-dom'
import SecondaryButton from '../../components/commonButton/SecondaryButton'

function AboutUs() {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        arrows: false
      };
  return (
    <>
          <Header />
          <section className='about-us-page'>
            <div className='container'>
                <div className='section-title'>
                    <PageHeading pageTitle={"About us"} />
                </div>

                <div className='aboutUsBanner'>
                    <h4>Skingener8</h4>
                    <p>a small yet passionate service provider, specialising in personalised care o transform everyday experiences into extraordinary memories. Our focus is on making you feel good and happy, leaving you with lasting positive emotions.Join us in this journey of enhancing your well-being and indulging in unique experiences that elevate your spirits.Let Skingener8 be your partner in crafting moments of delight and making your happiness a priority.</p>
                    <Slider {...settings}>
                        <img src={aboutUsImg1} className='w-100' />
                        <img src={aboutUsImg2} className='w-100' />
                        <img src={aboutUsImg3} className='w-100' />
                        <img src={aboutUsImg4} className='w-100' />
                        <img src={aboutUsImg5} className='w-100' />
                    </Slider>
                </div>
            </div>
                <div className='dream-box'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-md-6'>
                                <img src={perfectDreamImg} />
                            </div>
                            <div className='col-md-6 mt-4 mt-md-0'>
                                <h4>Dreaming your perfect look ?</h4>
                                <SecondaryButton btnLink={"treatment"} btnName={"Book a treatment "} />
                            </div>
                        </div>
                    </div>
                </div>
          </section>
          <Footer />
    </>
  )
}

export default AboutUs
