import React from 'react'
import './footer.scss'
import logo from './../../assets/images/footer-logo.png'
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate()
  return (
    <>
    <footer className='footer'>
        <div className='container'>
            <div className='footer-wrap'>
                <div className='footer-title'>
                    <h4><span>Renew</span><span>Reset</span><span>Regenere8</span></h4>
                </div>
                <hr className='custom-hr' />
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='logo-and-social'>
                            <div>
                                <img src={logo} />
                                <p>Skingener8 transforms everyday moments into extraordinary memories with personalized care.</p>
                            </div>
                            <div className='social-link'>
                                <a href="https://www.facebook.com/Skingener8"><FaFacebookF /></a>
                                <a href="https://x.com/skingener8"><FaXTwitter /></a>
                                <a href="https://www.tiktok.com/@skingener8"><FaTiktok /></a>
                                <a href="https://www.instagram.com/skingener8"><FaInstagram /></a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 mt-4 mt-md-0'>
                        <div className='box'>
                            <h4>Quick links</h4>
                            <ul>
                                <li><a onClick={() => {navigate("/treatment")}}>Treatments</a></li>
                                <li><a onClick={() => {navigate("/about-us")}}>About us</a></li>
                                <li><a onClick={() => {navigate("/meet-the-team")}}>Meet the team</a></li>
                                <li><a onClick={() => {navigate("")}}>Shop</a></li>
                                <li><a onClick={() => {navigate("/contact-us")}}>Contact us</a></li>
                                <li><a onClick={() => {navigate("/term-and-condition")}}>Terms & conditions</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-4 mt-4 mt-md-0'>
                        <div className='box'>
                            <div className='box-wrap'>
                                <h5>Address</h5>
                                <p>260 Pentonville Rd, Kings Cross London, N1 9JY</p>
                            </div>
                            <div className='box-wrap'>
                                <h5>Whatsapp/Call</h5>
                                <a href='https://api.whatsapp.com/send?phone=447534632576' target='_blank'>+44 7534 632576</a>
                            </div>
                            <div className='box-wrap'>
                                <h5>Mail</h5>
                                <a href='mailto:Service@skingener8.com'>Service@skingener8.com</a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='custom-hr' />
                <div className='copyright'>
                    <p>Powered by Faces</p>
                    <span>© Copyright 2024 • All Rights Reserved.</span>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer
